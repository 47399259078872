import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { CommandBar, DirectionalHint, IButtonProps, ICommandBarItemProps, IContextualMenuItem } from "@fluentui/react";

import { NodeType, TitleType } from "../../../contracts/models/strikeEnums";
import { ScreenWidthSize, useNodeAdminAccessControl, useScreenSize } from "../../../hooks";
import { ServiceHub } from "../../../service";
import { ApplicationRoutePaths } from "../../router";
import { appManagementSelectors } from "../../../redux/selectors/appManagement";
import { useSelector } from "react-redux";
import { IconButton } from "../../common/button";
import { nodesSelectors } from "../../../redux/selectors/nodes";

/**
 * Page Header Actions component contract props
 */
interface IPageHeaderActionsProps {
	Type: TitleType;
	enabled: undefined | boolean;
	onEdit?: () => void;
	onDelete?: () => void;
	onChangeSpace?: () => void;
	onStickySpace?: () => void;
	onStickyWebsite?: () => void;
	onShareContent: undefined | (() => void);
	onMakePrivate?: () => void;
	onExportToPdf?: () => void;
	isOwner?: undefined | boolean;
	onNodeRevision?: () => void;
	onReportContent?: () => void;
	onSendNodeToModeration?: () => void;
}

/**
 * Static Actions button configuration
 */
const overflowProps: IButtonProps = {
	ariaLabel: "Actions",
	title: "Actions",
	menuProps: {
		items: undefined,
		directionalHint: DirectionalHint.bottomRightEdge
	}
};

/**
 * Actions which should render inside the PageHeader component.
 */
export const PageHeaderActions: React.FC<IPageHeaderActionsProps> = (props) => {
	const renderTime = useRef(Date.now());
	const commandBarRef = useRef(null);
	const isMobile = useScreenSize(ScreenWidthSize.Mobile);
	const isTablet = useScreenSize(ScreenWidthSize.Tablet);
	const nodeAdminAccessControl = useNodeAdminAccessControl();
	const isHighZoomLevel = useSelector(appManagementSelectors.getIsHighZoomLevel);
	const isStickSpaceStatus = useSelector(nodesSelectors.getStickSpaceStatus);
	const isStickSiteStatus = useSelector(nodesSelectors.getStickSiteStatus);
	const isPrivateStatus = useSelector(nodesSelectors.getPrivateStatus);
	const isDeletedStatus = useSelector(nodesSelectors.getDeletedStatus);
	const currentType = useMemo(() => {
		return props.Type === TitleType.Kbentry
			? NodeType.Kbentry
			: props.Type === TitleType.Question
				? NodeType.Question
				: null;
	}, [props.Type]);

	/**
	 * Whether the user is an Edit-allowed context.
	 */
	const isEditableType = useMemo(() => [TitleType.Kbentry, TitleType.Question].includes(props.Type), [props.Type]);
	/**
	 * Whether the user is an Create-allowed context.
	 */
	const isCreatableType = useMemo(
		() => [TitleType.Dashboard, TitleType.KbentryDashboard, TitleType.QuestionDashboard].includes(props.Type),
		[props.Type]
	);
	/**
	 * Whether the user is an Delete-allowed context.
	 */
	const isDeletableType = useMemo(() => [TitleType.Kbentry, TitleType.Question].includes(props.Type), [props.Type]);

	/**
	 * Whether the Edit Actions are usable and active.
	 */
	const canUseEditActions = useMemo(() => {
		return props.enabled && isEditableType && (props?.isOwner || nodeAdminAccessControl.canAccess);
	}, [isEditableType, props.enabled, nodeAdminAccessControl.canAccess, props?.isOwner]);

	/**
	 * Whether create action are Usable and accessible.
	 */
	const canUseCreateActions = useMemo(() => {
		return (
			props.enabled && isCreatableType && nodeAdminAccessControl.canUserAccessNodeType(currentType)
			// (!currentType || nodeAdminAccessControl.canUserAccessNodeType(currentType))
		);
	}, [currentType, isCreatableType, props.enabled, nodeAdminAccessControl]);

	/**
	 * Whether Delete Action is enabled or not
	 */
	const canUseDeleteAction = useMemo(() => {
		return props.enabled && isDeletableType && (nodeAdminAccessControl.canAccess || props.isOwner);
	}, [isDeletableType, props?.enabled, nodeAdminAccessControl]);

	/**
	 * Redirects the UI to the Add New Article route.
	 *
	 * @param event
	 */
	const handleAddArticle = (
		ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
		item?: IContextualMenuItem
	) => {
		if (ev.preventDefault) {
			ev.preventDefault();
		}

		// Navigates to the article creation route
		ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.articleCreate());
	};

	/**
	 * Redirects the UI to the Ask a Question route.
	 *
	 * @param event
	 */
	const handleAskQuestion = (
		ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
		item?: IContextualMenuItem
	) => {
		if (ev.preventDefault) {
			ev.preventDefault();
		}

		// Navigates to the article creation route
		ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.questionCreate());
	};

	const createArticleAction = {
		key: "createAnArticle",
		cacheKey: "createAnArticleButton",
		text: "Post Article",
		ariaLabel: "Post Article",
		onClick: handleAddArticle,
		title: "Post Article",
		iconProps: {
			iconName: "AddToShoppingList"
		}
	};

	const createQuestionAction = {
		key: "askAQuestion",
		cacheKey: "askAQuestionButton",
		text: "Ask Question",
		ariaLabel: "Ask Question",
		onClick: handleAskQuestion,
		title: "Ask Question",
		iconProps: {
			iconName: "FeedbackRequestSolid"
		}
	};

	const editAction = {
		key: "editNode",
		text: `Edit`,
		ariaLabel: `Edit`,
		onClick: props?.onEdit ?? undefined,
		title: `Edit`,
		iconProps: { iconName: "PageEdit" }
	};

	const revisionAction = {
		key: "revisionNode",
		text: `Revisions`,
		ariaLabel: `Revisions`,
		onClick: props?.onNodeRevision ?? undefined,
		title: `Revisions`,
		iconProps: { iconName: "History" }
	};

	const deleteAction = {
		key: "deleteNode",
		text: `Delete`,
		ariaLabel: `Delete`,
		onClick: props?.onDelete ?? undefined,
		title: `Delete`,
		iconProps: { iconName: "Delete" }
	};

	const undeleteAction = {
		key: "undeleteNode",
		text: `Undelete`,
		ariaLabel: `Undelete`,
		onClick: props?.onDelete ?? undefined,
		title: `Undelete`,
		iconProps: { iconName: "RemoveFromTrash" }
	};

	const stickySpaceAction = {
		key: "stickySpace",
		text: `Stick to Space`,
		ariaLabel: `Stick to Space`,
		onClick: props?.onStickySpace ?? undefined,
		title: `Stick to Space`,
		iconProps: { iconName: "NotePinned" }
	};

	const unstickSpaceAction = {
		key: "unstickFromSpace",
		text: `Unstick from Space`,
		ariaLabel: `Unstick from Space`,
		onClick: props?.onStickySpace ?? undefined,
		title: `Unstick from Space`,
		iconProps: { iconName: "Unpin" }
	};

	const stickyWebsiteAction = {
		key: "stickyWebsite",
		text: `Stick to Website`,
		ariaLabel: `Stick to Website`,
		onClick: props?.onStickyWebsite ?? undefined,
		title: `Stick to Website`,
		iconProps: { iconName: "AddHome" }
	};

	const unstickWebsiteAction = {
		key: "unstickFromWebsite",
		text: `Unstick from Website`,
		ariaLabel: `Unstick from Website`,
		onClick: props?.onStickyWebsite ?? undefined,
		title: `Unstick from Website`,
		iconProps: { iconName: "HomeSolid" }
	};

	const makePrivateAction = {
		key: "makePrivate",
		text: `Make It Private`,
		ariaLabel: `Make It Private`,
		onClick: props?.onMakePrivate ?? undefined,
		title: `Make It Private`,
		iconProps: { iconName: "Lock" }
	};

	const makePublicAction = {
		key: "makePublic",
		text: `Make It Public`,
		ariaLabel: `Make It Public`,
		onClick: props?.onMakePrivate ?? undefined,
		title: `Make It Public`,
		iconProps: { iconName: "Unlock" }
	};

	const changeSpaceAction = {
		key: "changeNodeSpace",
		text: `Change Space`,
		ariaLabel: `Change Space`,
		onClick: props?.onChangeSpace ?? undefined,
		title: `Change Space`,
		iconProps: { iconName: "Switch" }
	};

	const shareContentAction = {
		key: "shareContent",
		text: `Share content`,
		ariaLabel: "Share content",
		onClick: props?.onShareContent ?? undefined,
		title: "Share Content",
		iconProps: {
			iconName: "Share"
		}
	};

	const reportContentAction = {
		key: "reportContent",
		text: `Report`,
		ariaLabel: "Report",
		onClick: props?.onReportContent ?? undefined,
		title: "Report",
		iconProps: {
			iconName: "Flag"
		}
	};

	const sendToModerationAction = {
		key: "sendToModeration",
		text: `Send To Moderation`,
		ariaLabel: "Send To Moderation",
		onClick: props?.onSendNodeToModeration ?? undefined,
		title: "Send To Moderation",
		iconProps: {
			iconName: "Send"
		}
	};

	const exportToPdfAction = {
		key: "exportToPdf",
		text: `Export to PDF`,
		ariaLabel: "Export to PDF",
		onClick: props?.onExportToPdf ?? undefined,
		title: "Export to PDF",
		iconProps: {
			iconName: "PDF"
		}
	};

	const editItems = useMemo<ICommandBarItemProps[]>(
		() => [
			{
				key: "editMenu",
				cacheKey: "editMenu",
				text: "",
				iconProps: {
					iconName: "Settings"
				},
				subMenuProps: {
					directionalHint: DirectionalHint.bottomRightEdge,
					items: [
						...(canUseEditActions ? [editAction] : []),
						...(canUseEditActions ? [changeSpaceAction] : []),
						...(props.onStickySpace && canUseEditActions
							? [isStickSpaceStatus ? unstickSpaceAction : stickySpaceAction]
							: []),
						...(props.onStickyWebsite && canUseEditActions
							? [isStickSiteStatus ? unstickWebsiteAction : stickyWebsiteAction]
							: []),
						...(typeof props?.onShareContent === "function" ? [shareContentAction] : []),
						...(props.onMakePrivate && canUseEditActions
							? [isPrivateStatus ? makePublicAction : makePrivateAction]
							: []),
						...(canUseDeleteAction ? [isDeletedStatus ? undeleteAction : deleteAction] : []),
						...(typeof props?.onExportToPdf === "function" ? [exportToPdfAction] : []),
						...(canUseEditActions ? [revisionAction] : []),
						...(typeof props?.onSendNodeToModeration === "function" ? [sendToModerationAction] : []),
						...(typeof props?.onReportContent === "function" ? [reportContentAction] : []),
					]
				}
			}
		],
		[canUseEditActions, canUseDeleteAction, editAction, deleteAction, shareContentAction]
	);

	useEffect(() => {
		const divElement = commandBarRef.current;
		if (divElement) {
			const parentDiv = document.getElementById('cmdNodeSettings');
			if (parentDiv) {
				const childElement = parentDiv.querySelector('button');
				if (childElement) {
					let cls = childElement.className;
					childElement.setAttribute('title', 'Node Settings');
				}
			}
		}
	}, []);

	/**
	 * The list which will be applied a protection layer,
	 * whether the accessing user has permission for Node Admin
	 * or not, will render these in the list or not.
	 */
	const specialCreateComponents = useMemo<ICommandBarItemProps[]>(() => [createArticleAction], [createArticleAction]);

	const createItems = useMemo<ICommandBarItemProps[]>(() => {
		const createModeButtons = [];

		if (canUseCreateActions) {
			createModeButtons.push(...specialCreateComponents);
		}

		// Create Question is always allowed
		createModeButtons.push(createQuestionAction);

		if (isMobile || isTablet || isHighZoomLevel) {
			return [
				{
					key: "createMenu",
					cacheKey: "createMenu",
					text: "",
					ariaLabel: "Add",
					iconProps: {
						iconName: "Add"
					},
					subMenuProps: {
						directionalHint: DirectionalHint.bottomRightEdge,
						items: createModeButtons
					}
				}
			];
		}

		return [...createModeButtons];
	}, [canUseCreateActions, specialCreateComponents, createQuestionAction, isMobile, isTablet, isHighZoomLevel]);

	/**
	 * Renders the actions as a CommandBar component.
	 *
	 * @returns JSX.Element
	 */
	const renderActions = useCallback(() => {
		const actionsRendering: ICommandBarItemProps[] = [];

		// 1. Define whether to display Create actions: + Article / + Question
		if (isCreatableType) {
			if (createItems.length > 0) {
				actionsRendering.push(...createItems);
			}
		}

		// // 2. Define whether to display Edit actions: Edit / Make it Private / Delete
		if (currentType) {
			if (editItems.length > 0) {
				actionsRendering.push(...editItems);
			}
		}

		renderTime.current = Date.now();

		// 3. Render the command bar appropriately
		return isMobile || isTablet || isHighZoomLevel || currentType ? (
			<CommandBar
				items={actionsRendering}
				ariaLabel="Node Settings"
				title="Node Settings"
				overflowButtonProps={overflowProps}
				onReduceData={() => undefined}
				id="cmdNodeSettings"
				componentRef={commandBarRef}
			/>
		) : (
			<div className="action-group-buttons">
				{actionsRendering.map((button) => {
					return (
						<IconButton
							key={button.key}
							onClick={(ev) => {
								button.onClick(ev);
							}}
							iconName={button.iconProps.iconName}
							text={button.title}
						/>
					);
				})}
			</div>
		);
	}, [
		canUseCreateActions,
		canUseEditActions,
		createItems,
		editItems,
		isMobile,
		renderTime.current,
		isCreatableType,
		isEditableType
	]);

	return renderActions();
};
