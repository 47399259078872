import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { INodeInfo } from "../../contracts/models";
import { NodeType } from "../../contracts/models/strikeEnums";
import { Topics } from "../topics/topics.list";
import { QuestionIcon, BookIcon, CheckIcon, DefaultIcon } from "../common/icon";
import { UserPhoto } from "../common/user";
import { ApplicationRoutePaths } from "../router";
import { mapNodeActions, utcToLocalDateTimeString } from "../../lib/strikeLibrary";
import { FavoriteIcon } from "../common/icon/favorite.icon";

/**
 * NodeSummary input props
 */
export interface INodeSummaryProps {
	Index: number;
	NodeInfo: INodeInfo;
}

/**
 * Processes a unit of Node Summary.
 * Should render a line with content describing the
 * Article, or whatever the content should be.
 *
 * @param props Properties inbound.
 * @returns React.FC
 */
export const NodeSummary: React.FC<INodeSummaryProps> = (props) => {
	/**
	 * Extraction to obtain the proper URL using predictable methods.
	 */
	const nodeLinkUrl = useMemo(() => {
		return props.NodeInfo.Type === NodeType.Kbentry
			? ApplicationRoutePaths.article(props.NodeInfo.NodeId.toString(), props.NodeInfo.Title)
			: props.NodeInfo.Type === NodeType.Question
				? ApplicationRoutePaths.question(props.NodeInfo.NodeId.toString(), props.NodeInfo.Title)
				: "#";
	}, [props.NodeInfo]);

	/**
	 * Pre-maps the latest Node action within the info.
	 */
	const nodeActionsMap = useMemo(() => {
		return mapNodeActions(props.NodeInfo.LastActivity_ActionType);
	}, [props.NodeInfo]);

	/**
	 * Reads the date of the last Node interaction available from info.
	 */
	const actionDate = useMemo(() => {
		return utcToLocalDateTimeString(props.NodeInfo.LastActivity_ActionDate);
	}, [props.NodeInfo]);

	const actionMap: { [key: string]: string } = {
		commented: "commented",
		newkbentry: "created article",
		answered: "answered",
		asked: "created question",
		answeredtocomment: "commented"
	};

	const nodeSummaryTitle = `${props.NodeInfo.Type === NodeType.Kbentry ? "Article" : "Question"}: ${props.NodeInfo.Title
		}`;

	const nodeSummaryItemGroupTitle = `Summary List Item number: ${props.Index + 1}`;

	const actionName = useMemo(() => {
		return actionMap[nodeActionsMap] ?? nodeActionsMap;
	}, [actionMap, nodeActionsMap]);

	const actionAriaLabel = useMemo(() => {
		return `Last activity: ${props.NodeInfo.LastActivity_UserFullName} ${actionName}, on ${actionDate}`;
	}, [actionName, actionDate]);

	/**
	 * Resolved Topics value after filtering any duplication coming from api
	 * Returning a string comma separated list
	 */
	const { filteredTopicsString, filteredTopicsIdsString } = useMemo(() => {
		const topicsArray = props.NodeInfo.Topics.split(",");
		const topicsIdsArray = props.NodeInfo.TopicsId.split(",");
	  
		const combinedTopics = topicsArray.map((topic, index) => ({
		 topic: topic.trim(),
		 id: topicsIdsArray[index]?.trim()
		}));
	  
		const filteredTopics = combinedTopics.filter(
		 (value, index, self) => self.findIndex((t) => t.topic === value.topic) === index
		);
	  
		const filteredTopicsArray = filteredTopics.map((item) => item.topic);
		const filteredTopicsIdsArray = filteredTopics.map((item) => item.id);
	  
		const filteredTopicsString = filteredTopicsArray.join(",");
		const filteredTopicsIdsString = filteredTopicsIdsArray.join(",");
	  
		return { filteredTopicsString, filteredTopicsIdsString };
	   }, [props.NodeInfo.Topics, props.NodeInfo.TopicsId]);

	return (
		<div key={props.Index} className="node-list" aria-label={nodeSummaryItemGroupTitle} role="none">
			<div className="node-list-box card mt-2 node-summary-list" tabIndex={-1}>
				<div className="p-2 card-body">
					<div className="align-items-center row">
						<div className="col-auto node-image-pr-pl ml-2">
							<div className="node-list-images pl-3em" tabIndex={-1}>
								{props.NodeInfo.Type === NodeType.Kbentry ? (
									<BookIcon inverted title={"Article"} />
								) : (
									""
								)}
								{props.NodeInfo.Type === NodeType.Question ? (
									<QuestionIcon inverted title={"Question"} />
								) : (
									""
								)}
							</div>
						</div>
						<div className="col-auto node-image-pr-pl">
							<div className="node-list-images" tabIndex={-1}>
								<UserPhoto
									userPrincipalName={props.NodeInfo.LastActivity_UserEmail}
									alt={`Profile: ${props.NodeInfo.LastActivity_UserFullName}`}
									title={props.NodeInfo.LastActivity_UserFullName}
									size="lg"
								/>
							</div>
						</div>
						<div className="col-lg-7">
							<div className="node-list-content mt-3 mt-lg-0">
								<div className="fs-12 mb-1 node-summary-list-favorite-headline">
									<Link
										className="primary-link"
										to={nodeLinkUrl}
										rel="noopener noreferrer"
										title={nodeSummaryTitle}
										aria-label={nodeSummaryTitle}
									>
										{props.NodeInfo.Title}
									</Link>
								</div>
								{props.NodeInfo.IsFavourite ? <FavoriteIcon tabIndex={0} title={"Favorite"} color='red' className='node-summary-list-favorite-icon' /> : null}
								<p className="text-muted last-activity-user mb-1">
									<span className="bold-600-text">{props.NodeInfo.LastActivity_UserFullName}</span>
									&nbsp;
									<Link className="primary-link" to={nodeLinkUrl} aria-label={actionAriaLabel}>
										{actionName}
									</Link>
									<span className="bold-700-text">.</span>
									<span>{" " + actionDate}</span>
								</p>
								<div className="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
									<Topics Topics={filteredTopicsString} TopicsIds={filteredTopicsIdsString} />
								</div>
							</div>
						</div>

						<div className="col-lg-3 node-list-actions">
							<div className="row align-items-center">
								<div className="col node-list-content text-align-center">
									{props.NodeInfo.IsQuestionAnswered ? (
										<div tabIndex={-1}>
											{" "}
											<CheckIcon color="green" />
											<p
												tabIndex={-1}
												className="last-activity-user mb-1 text-green"
												title="One or more accepted answers"
												aria-label="This Question has one or more accepted answers"
											>
												Accepted
											</p>
										</div>
									) : (
										""
									)}
								</div>
								<div className={`col node-list-content text-align-center ${props.NodeInfo.AnswersCount ?  'answers' : ''}`}>
									{props.NodeInfo.Type === NodeType.Question ? (
										<div
											title={`${props.NodeInfo.AnswersCount ?? 0} Answers`}
											aria-label={`${props.NodeInfo.AnswersCount ?? 0} Answers`}
										>
											<p className="text-muted last-activity-user activity-number">
												<b>{props.NodeInfo.AnswersCount}</b>
											</p>
											<p className="text-muted last-activity-user mb-1" aria-hidden="true">Answers</p>
										</div>
									) : null}
								</div>
								<div
									className="col node-list-content likes text-align-center"
									title={`${props.NodeInfo.LikeCount ?? 0} Likes`}
									aria-label={`${props.NodeInfo.LikeCount ?? 0} Likes`}
								>
									<p className="text-muted last-activity-user activity-number">
										<b>{props.NodeInfo.LikeCount}</b>
									</p>
									<p className="text-muted last-activity-user mb-1" aria-hidden="true">Likes</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
