import React, { useEffect, useMemo, useRef } from "react";
import { IContextualMenuItem, IIconProps } from "@fluentui/react";
import { useFilterSlice } from "../../hooks/filter/useFilterSlice";
import { ServiceHub } from "../../service";
import { ButtonDropdown } from "../common/button";
import { UserDashboardsFiltersType } from "../../contracts/models/strikeEnums";
import { useNavigate, useParams, useLocation } from "react-router-dom";

/**
 * Renders a re-usable Action Button group for Strike App.
 *
 * @returns React.FC
 */
export const UserDashboardsDropdown: React.FC = () => {
	const params = useParams();
	const filterData = useFilterSlice();
	const searchTopics = filterData.searchTopics;
	const searchTopic = filterData.searchTopic;
	const searchTitleValue = filterData.searchTitle;
	const userCustomDashboard = filterData.userCustomDashboard;
	const filterAPI = useRef(ServiceHub.filterAPI.start());
	const navigate = useNavigate();	

	/**
	 * Utility method to process filter type and get nodes
	 * @param filterType 
	 */
	const handleCustomDashboardSelection = (filterBy: UserDashboardsFiltersType) => {
		let queryModel = ServiceHub.nodeAPI.getQueryNodeObject(
			Number(params.id),
			1,
			searchTitleValue,
			searchTopics,
			searchTopic,
			undefined,
			undefined,
			filterBy
		);
		filterData.getNodesList(queryModel);
	}

	/**
	 * Reset Page Header to Home Dashboard values
	 */
	const resetPagHeader = () => {
		const headerIcon = document.querySelector('.page-header-icon i');
		const headerText = document.querySelector('.page-header-text');
		headerText.innerHTML = "All Posts"
		headerIcon.classList.add('list');
		if(headerIcon.classList.contains('question')) {
			headerIcon.classList.remove('question', 'circle', 'outline')
		} else {
			headerIcon.classList.remove('file', 'alternate', 'outline')
		}
	}


	/**
	 * Common UI method to receive User Selected Dashboard.
	 *
	 * @param userDashboardType
	 */
	function resetUserDashboardView(userDashboardType : UserDashboardsFiltersType) {
		filterAPI.current.resetDashBoardButtonTitle();
		filterAPI.current.setDashboardNavigation(undefined);
		filterAPI.current.resetsortByOption();
		filterAPI.current.setUserCustomDashboard(userDashboardType);
		handleCustomDashboardSelection(userDashboardType);
		// prevent unnecesary refresh page affecting narrator
		window.history.replaceState(null, "Strike Community Home", "/")
		resetPagHeader();
		document.title = 'Strike Community'
	}

	/**
	 * List of the buttons which will be rendered in the dropdown.
	 */
	const userDashboardButtonItems = useMemo(
		() => [
			{
				key: "userDashboardMyActivity",
				text: "My Activity",
				onClick: () => resetUserDashboardView(UserDashboardsFiltersType.Activity),
				title: "Activate to View My Activity",
				iconProps: {
					iconName: "AccountActivity"
				}
			},
            {
				key: "userDashboardMyFollowers",
				text: "My Followers",
				onClick: () => resetUserDashboardView(UserDashboardsFiltersType.Followers),
				title: "Activate to View My Followers",
				iconProps: {
					iconName: "Group"
				}
			},
            {
				key: "userDashboardMyUnanswered",
				text: "My Unanswered",
				onClick: () => resetUserDashboardView(UserDashboardsFiltersType.MyUnanswered),
				title: "Activate to View My Unanswered",
				iconProps: {
					iconName: "ChatInviteFriend"
				}
			},
            {
				key: "userDashboardAllUnanswered",
				text: "All Unanswered",
				onClick: () => resetUserDashboardView(UserDashboardsFiltersType.AllUnanswered),
				title: "Activate to View All Unanswered",
				iconProps: {
					iconName: "OfficeChat"
				}
			},
            {
				key: "userDashboardMyFavorites",
				text: "My Favorites",
				onClick: () => resetUserDashboardView(UserDashboardsFiltersType.MyFavorite),
				title: "Activate to View My Favorite",
				iconProps: {
					iconName: "Heart"
				}
			},
            {
				key: "userDashboardAll",
				text: "All",
				onClick: () => resetUserDashboardView(UserDashboardsFiltersType.All),
				title: "Activate to View All",
				iconProps: {
					iconName: "PageList"
				}
			},

		],
		[]
	);

    /**
	 * prepared memo to detect location and set button label name and icon
	 */
	const mainButtonLabel = useMemo(() => {
		if (userCustomDashboard === UserDashboardsFiltersType.MyFavorite) {
			return { name : 'My Favorites', icon : 'Heart' }
		} else if (userCustomDashboard === UserDashboardsFiltersType.Activity) {
			return { name : 'My Activity', icon : 'AccountActivity' }
		} else if (userCustomDashboard === UserDashboardsFiltersType.Followers) {
			return { name : 'My Followers', icon : 'Group' }
		} else if (userCustomDashboard === UserDashboardsFiltersType.MyUnanswered) {
			return { name : 'My Unanswered', icon : 'ChatInviteFriend' }
		} else if (userCustomDashboard === UserDashboardsFiltersType.AllUnanswered) {
			return { name : 'All Unanswered', icon : 'OfficeChat' }
		} else {
            return { name : 'My Dashboards', icon : 'PageList' }
		}
	}, [userCustomDashboard]);

    const labelIcon: IIconProps = { iconName: mainButtonLabel.icon };

	return (
		<ButtonDropdown
			fullWidth
            iconProps={labelIcon}
			callToActionText={mainButtonLabel.name}
			className="user-dashboards-dropdown"
			items={userDashboardButtonItems}
		/>
	);
};