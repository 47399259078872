import React from "react";
import { FormLabels } from "./form.labels";
import { FieldRequiredMark } from "./field.requiredMark";
import { Alert } from "../alert";

/**
 * Re-usable Form Container component domain definition.
 */
export interface IFormContainerProps {
	children: React.ReactElement | React.ReactElement[] | JSX.Element | JSX.Element[];
	onSubmit: (event: React.FormEvent<HTMLFormElement>) => void | Promise<void>;
	title?: undefined | string;
	useModerationAlert?: undefined | boolean;
	hideShadow?: undefined | boolean;
	hideLateralPadding?: undefined | boolean;
	hideRequiredNotice?: undefined | boolean;
	transparentBg?: undefined | boolean;
}

/**
 * Form Container component. Re-usable for the Strike App.
 *
 * @param props IFormContainerProps
 * @returns React.FC<IFormContainerProps>
 */
export const FormContainer: React.FC<IFormContainerProps> = (props) => {
	const alertTextAriaLabel =
		`Info: After you submit your article, ` +
		`it will be subject to a “Moderation Review” by the ` +
		`Strike Online Team. Team will review and, if approved, ` +
		`publish your article. You will receive a notification once it is live on the site.`;
	const renderAlertText = () => {
		return (
			<>
				After you submit your article, it will be subject to a “Moderation Review” by the{" "}
				<a href="mailto:strikeonlineteam@microsoft.com">Strike Online Team</a>.<br />
				Team will review and, if approved, publish your article. You will receive a notification once it is live
				on the site.
			</>
		);
	};

	return (
		<form
			className={`form-container ${!props?.hideShadow ? "box-shadow" : ""} ${
				props?.hideLateralPadding ? "no-lateral-padding" : ""
			} ${props?.transparentBg ? "transparent-background" : ""}`}
			onSubmit={props.onSubmit}
		>
			{props?.useModerationAlert ? (
				<div className="form-instructions">
					<Alert
						text={
							<div role="banner" aria-label={alertTextAriaLabel}>
								{renderAlertText()}
							</div>
						}
					/>
				</div>
			) : null}
			<div className="form-top">
				{undefined !== props?.title && props?.title !== "" ? <h1>{props.title}</h1> : ""}
				{!props?.hideRequiredNotice ? (
					<div className="required-info-note" aria-label={FormLabels.requiredInfo}>
						<FieldRequiredMark />
						&nbsp;{FormLabels.requiredInfo}
					</div>
				) : null}
			</div>
			{props.children}
			{props?.useModerationAlert ? (
				<div className="form-instructions-bottom" tabIndex={0} role="note" aria-label={alertTextAriaLabel}>
					<b>Please, note:</b>&nbsp;
					{renderAlertText()}
				</div>
			) : null}
		</form>
	);
};
