import { useDispatch, useSelector } from "react-redux";
import { nodesSelectors } from "../../redux/selectors/nodes";
import {
	setDeleteConfirmationModalSubText,
	setDeleteConfirmationModalTitle,
	setIsActionConfirmed,
	setIsOpenDeleteConfirmation,
	setIsChangeSpacePanelOpen,
	setStickSpaceStatus,
	setStickSiteStatus,
	setIsOpenStickConfirmation,
	setIsOpenPrivateConfirmation,
	setPrivateStatus,
	setDeletedStatus,
	setDeleteConfirmationModalButtonText,
	setIsRevisionsPanelOpen,
	setNodeRevisions,
	resetNodeRevisions,
	setRevisionsLoaded
} from "../../redux/reducers/nodes";
import { ServiceHub } from "../../service";
import { NodeActionTypes, NodeType } from "../../contracts/models/strikeEnums";
import { NodeAPIServiceInstance } from "../../service/NodeAPI";
import { StickyNodeRequest, UpdateNodeStatusActionModel, UserActivityModel } from "../../contracts/models";
import Constants from "../../constants/Constants";
import { ITreeItem } from "@coherence-design-system/tree-view";

/**
 * Contract for the Node Actions hook.
 */
interface INodeActionsSliceHook {
	isOpenDeleteConfirmation: boolean;
	isOpenStickConfirmation: boolean;
	isOpenShareContent: boolean;
	isSubmittingShareContent: boolean;
	isOpenPrivateConfirmation: boolean;
	onClose: () => void;
	onCloseStickyConfirmation: () => void;
	onClosePrivateConfirmation: () => void;
	dismissChangeSpacePanel: () => void;
	openChangeSpacePanel: () => void;
	dismissRevisionsPanel: () => void;
	openRevisionsPanel: () => void;
	onDeleteConfirmation: (nodeId: string, actionType: NodeActionTypes, userUpn: string, type?: NodeType) => void;
	onUndeleteConfirmation: (nodeId: string, actionType: NodeActionTypes, userUpn: string, type?: NodeType) => void;
	onPublishConfirmation: (actionType: NodeActionTypes, ipAddress: string, nodeId: string, emailID: string) => void;
	isActionConfirmed: boolean;
	confirmationModalTitle: string;
	confirmationModalSubText: string;
	confirmationModalButtonText: string;
	confirmationModalAction: () => void;
	confirmationStickyAction: () => void;
	isOpenActionPostMessage: boolean;
	isChangeSpacePanelOpen: boolean;
	isRevisionsPanelOpen: boolean;
	onAddStickNode: (nodeId: string, spaceId?: string | null) => void;
	onRemoveStickNode: (nodeId: string, spaceId?: string | null) => void;
	onGetNodeRevisions: (nodeId: string) => void;
	onMakeNodePrivate: (nodeId: string, actionType: NodeActionTypes, userUpn: string, type?: NodeType) => void;
	onMakeNodePublic: (nodeId: string, actionType: NodeActionTypes, userUpn: string, type?: NodeType) => void;
	selectedReportReasonItem: any;
	reportReasons: ITreeItem[];
	reportReasonsOptionsData: string[]
}

/**
 * Hook to use shared Node Actions globally
 *
 * @returns INodeActionsSliceHook
 */
export const useNodeActionsSlice = (): INodeActionsSliceHook => {
	const dispatch = useDispatch();
	const isOpenDeleteConfirmation: boolean = useSelector(nodesSelectors.getIsOpenDeleteConfirmation);
	const isOpenStickConfirmation: boolean = useSelector(nodesSelectors.getIsOpenStickConfirmation);
	const isOpenPrivateConfirmation: boolean = useSelector(nodesSelectors.getIsOpenPrivateConfirmation);
	const isOpenActionPostMessage: boolean = useSelector(nodesSelectors.getIsOpenActionPostMessage);
	const isOpenShareContent: boolean = useSelector(nodesSelectors.getIsOpenShareContent);
	const reportReasons = useSelector(nodesSelectors.getReportReasonValues);
	const selectedReportReasonItem = useSelector(nodesSelectors.getSelectedReportReasonById);
	const reportReasonsOptionsData: string[] = useSelector(nodesSelectors.getReportReasonsData);
	const isActionConfirmed: boolean = useSelector(nodesSelectors.getIsActionConfirmed);
	const isChangeSpacePanelOpen: boolean = useSelector(nodesSelectors.getIsChangeSpacePanelOpen);
	const isRevisionsPanelOpen: boolean = useSelector(nodesSelectors.getIsRevisionsPanelOpen);
	const isSubmittingShareContent: boolean = useSelector(nodesSelectors.getIsSubmittingShareContent);
	const confirmationModalTitle: string = useSelector(nodesSelectors.getNodeConfirmationTitle);
	const confirmationModalSubText: string = useSelector(nodesSelectors.getNodeConfirmationSubText);
	const confirmationModalButtonText: string = useSelector(nodesSelectors.getNodeConfirmationButtonText);
	const confirmationModalAction: () => void = useSelector(nodesSelectors.getConfirmationModalAction);
	const confirmationStickyAction: () => void = useSelector(nodesSelectors.getConfirmationStickyAction);

	/**
	 * Generic method to get focus back to node settings button
	 */
	const focusBackSettingsButton = () => {
		const nodeSettingsButton = document.querySelector<HTMLButtonElement>('.page-header-actions button')
		if(nodeSettingsButton) {
			nodeSettingsButton.focus();
		}
	}
	/**
	 * Triggered on Generic Confirmation modal close action
	 */
	const onClose = (): void => {
		dispatch(setIsOpenDeleteConfirmation(false));
		dispatch(setDeleteConfirmationModalButtonText(""));
	};

	/**
	 * Triggered on Sticky features Confirmation modal close action
	 */
	const onCloseStickyConfirmation = (): void => {
		dispatch(setIsOpenStickConfirmation(false));
	};

	/**
	 * Triggered on Private/Public features Confirmation modal close action
	 */
	const onClosePrivateConfirmation = (): void => {
		dispatch(setIsOpenPrivateConfirmation(false));
	};

	/**
	 * Exit action on Change Space feature cancel button
	 */
	const dismissChangeSpacePanel = (): void => {
		dispatch(setIsChangeSpacePanelOpen(false));
		focusBackSettingsButton();
	};

	/**
	 * Calling Panel to show Change Space feature form
	 */
	const openChangeSpacePanel = (): void => {
		dispatch(setIsChangeSpacePanelOpen(true));
	};


	/**
	 * Exit action on Revisions node info panel
	 */
	const dismissRevisionsPanel = (): void => {
		dispatch(setIsRevisionsPanelOpen(false));
		dispatch(resetNodeRevisions())
		focusBackSettingsButton();
	};

	/**
	 * Calling Panel to show Revisions content
	 */
	const openRevisionsPanel = (): void => {
		dispatch(setIsRevisionsPanelOpen(true));
	};

	/**
	 * Manage object data used on Delete node feature
	 * @param nodeId
	 * @param actionType
	 * @param userUpn
	 * @param type
	 */
	const onDeleteConfirmation = (
		nodeId: string,
		actionType: NodeActionTypes,
		userUpn: string,
		type: NodeType
	): void => {
		let queryModel = ServiceHub.nodeAPI.getQueryUpdateStatusNodeObject(actionType, userUpn, nodeId);
		deleteNode(queryModel, type);
	};

	/**
	 * Calling API endpoint to delete a node action
	 * @param queryDeleteNode
	 * @param type
	 */
	const deleteNode = async (queryDeleteNode: UpdateNodeStatusActionModel, type: NodeType) => {
		let res;
		if (type === NodeType.Kbentry) {
			res = await ServiceHub.nodeAdminAPI.start().UpdateNodeStatus(queryDeleteNode);
		} else {
			res = await NodeAPIServiceInstance.UpdateNodeStatus(queryDeleteNode);
		}
		if (res) {
			dispatch(setIsActionConfirmed(true));
			dispatch(setDeleteConfirmationModalTitle(Constants.deleteConfirmationTexts.DeleteSuccess));
			dispatch(setDeleteConfirmationModalSubText(""));
			dispatch(setDeletedStatus(true));
			dispatch(setDeleteConfirmationModalButtonText(""));
		}
	};

	/**
	 * Manage object data to use on Undelete node action
	 * @param nodeId
	 * @param actionType
	 * @param userUpn
	 * @param type
	 */
	const onUndeleteConfirmation = (
		nodeId: string,
		actionType: NodeActionTypes,
		userUpn: string,
		type: NodeType
	): void => {
		let queryModel = ServiceHub.nodeAPI.getQueryUpdateStatusNodeObject(actionType, userUpn, nodeId);
		undeleteNode(queryModel, type);
	};

	/**
	 * Calling API endpoints to execute undelete node
	 * @param queryDeleteNode
	 * @param type
	 */
	const undeleteNode = async (queryDeleteNode: UpdateNodeStatusActionModel, type: NodeType) => {
		let res;
		if (type === NodeType.Kbentry) {
			res = await ServiceHub.nodeAdminAPI.start().UpdateNodeStatus(queryDeleteNode);
		} else {
			res = await NodeAPIServiceInstance.UpdateNodeStatus(queryDeleteNode);
		}
		if (res) {
			dispatch(setIsActionConfirmed(true));
			dispatch(setDeleteConfirmationModalTitle(Constants.undeleteConfirmationTexts.UndeleteSuccess));
			dispatch(setDeleteConfirmationModalSubText(""));
			dispatch(setDeletedStatus(false));
			dispatch(setDeleteConfirmationModalButtonText(""));
		}
	};

	/**
	 * Manage object data to publish node action
	 * @param actionType
	 * @param ipAddress
	 * @param nodeId
	 * @param emailID
	 */
	const onPublishConfirmation = (
		actionType: NodeActionTypes,
		ipAddress: string,
		nodeId: string,
		emailID: string
	): void => {
		let queryModel = ServiceHub.nodeAPI.getQueryPublishNodeObject(actionType, ipAddress, nodeId, emailID);
		publishNode(queryModel);
	};

	/**
	 * API endpoints call via service to publish article
	 * @param queryPublishArticle
	 */
	const publishNode = async (queryPublishArticle: UserActivityModel) => {
		let res = await ServiceHub.nodeAdminAPI.start().publishArticle(queryPublishArticle);
		if (res) {
			dispatch(setIsActionConfirmed(true));
			dispatch(setDeleteConfirmationModalTitle(Constants.publishConfirmationTexts.ArticlePublished));
			dispatch(setDeleteConfirmationModalSubText(""));
		}
	};

	/**
	 * Add Stick article to space or website
	 * @param nodeId
	 * @param spaceId
	 */
	const onAddStickNode = (nodeId: string, spaceId?: string): void => {
		let queryModel = ServiceHub.nodeAPI.getQueryStickNodeObject(nodeId, spaceId);
		addStickNode(queryModel);
	};

	/**
	 * Calling API via service to process sticky feature
	 * @param queryStickyNode
	 */
	const addStickNode = async (queryStickyNode: StickyNodeRequest) => {
		let res = await ServiceHub.nodeAdminAPI.start().addStickNode(queryStickyNode);
		if (res) {
			dispatch(setIsOpenStickConfirmation(false));
			if (queryStickyNode.spaceId) {
				dispatch(setStickSpaceStatus(true));
			} else {
				dispatch(setStickSiteStatus(true));
			}
		}
	};

	/**
	 * Manage object data to prep set node as Private setting
	 * @param nodeId
	 * @param actionType
	 * @param userUpn
	 * @param type
	 */
	const onMakeNodePrivate = (nodeId: string, actionType: NodeActionTypes, userUpn: string, type: NodeType): void => {
		let queryPrivateNode = ServiceHub.nodeAPI.getQueryUpdateStatusNodeObject(actionType, userUpn, nodeId);
		makeNodePrivate(queryPrivateNode, type);
	};

	/**
	 * API endpoints call to set node Private switching between node type
	 * @param queryPrivateNode
	 * @param type
	 */
	const makeNodePrivate = async (queryPrivateNode: UpdateNodeStatusActionModel, type: NodeType) => {
		let res;
		if (type === NodeType.Kbentry) {
			res = await ServiceHub.nodeAdminAPI.start().UpdateNodeStatus(queryPrivateNode);
		} else {
			res = await NodeAPIServiceInstance.UpdateNodeStatus(queryPrivateNode);
		}
		if (res) {
			dispatch(setIsOpenPrivateConfirmation(false));
			dispatch(setPrivateStatus(true));
		}
	};

	/**
	 * Managing object data to set a node back as Public
	 * @param nodeId
	 * @param actionType
	 * @param userUpn
	 * @param type
	 */
	const onMakeNodePublic = (nodeId: string, actionType: NodeActionTypes, userUpn: string, type: NodeType): void => {
		let queryPrivateNode = ServiceHub.nodeAPI.getQueryUpdateStatusNodeObject(actionType, userUpn, nodeId);
		makeNodePublic(queryPrivateNode, type);
	};

	/**
	 * Calling API endpoints via service to set node as Public
	 * @param queryPrivateNode
	 * @param type
	 */
	const makeNodePublic = async (queryPrivateNode: UpdateNodeStatusActionModel, type: NodeType) => {
		let res;
		if (type === NodeType.Kbentry) {
			res = await ServiceHub.nodeAdminAPI.start().UpdateNodeStatus(queryPrivateNode);
		} else {
			res = await NodeAPIServiceInstance.UpdateNodeStatus(queryPrivateNode);
		}
		if (res) {
			dispatch(setIsOpenPrivateConfirmation(false));
			dispatch(setPrivateStatus(false));
		}
	};

	/**
	 * Remove Stick article to space or website
	 * @param nodeId
	 * @param spaceId
	 */
	const onRemoveStickNode = (nodeId: string, spaceId?: string): void => {
		let queryModel = ServiceHub.nodeAPI.getQueryStickNodeObject(nodeId, spaceId);
		removeStickNode(queryModel);
	};

	/**
	 * Call Node Admin API method to get Revisions
	 * @param nodeId
	 */
	const onGetNodeRevisions = (nodeId: string): void => {
		dispatch(setRevisionsLoaded(false));
		getNodeRevisions(nodeId);
	};

	/**
	 * Calling API to remove stick on node
	 * @param queryStickyNode
	 */
	const removeStickNode = async (queryStickyNode: StickyNodeRequest) => {
		let res = await ServiceHub.nodeAdminAPI.start().removeStickNode(queryStickyNode);
		if (res) {
			dispatch(setIsOpenStickConfirmation(false));
			if (queryStickyNode.spaceId) {
				dispatch(setStickSpaceStatus(false));
			} else {
				dispatch(setStickSiteStatus(false));
			}
		}
	};

	/**
	 * Calling API to get Node Revisions
	 * @param nodeId
	 */
	const getNodeRevisions = async (nodeId: string) => {
		let res = await ServiceHub.nodeAdminAPI.start().getNodeRevisions(nodeId);
		if (res) {
			dispatch(setNodeRevisions(res))
			dispatch(setRevisionsLoaded(true));
		}
	};

	return {
		isOpenDeleteConfirmation,
		isOpenStickConfirmation,
		isOpenShareContent,
		isSubmittingShareContent,
		isOpenPrivateConfirmation,
		onClose,
		onCloseStickyConfirmation,
		onClosePrivateConfirmation,
		dismissChangeSpacePanel,
		openChangeSpacePanel,
		dismissRevisionsPanel,
		openRevisionsPanel,
		onDeleteConfirmation,
		onUndeleteConfirmation,
		onPublishConfirmation,
		isActionConfirmed,
		confirmationModalTitle,
		confirmationModalSubText,
		confirmationModalButtonText,
		confirmationModalAction,
		confirmationStickyAction,
		isOpenActionPostMessage,
		isChangeSpacePanelOpen,
		isRevisionsPanelOpen,
		onAddStickNode,
		onRemoveStickNode,
		onGetNodeRevisions,
		onMakeNodePrivate,
		onMakeNodePublic,
		reportReasons,
		selectedReportReasonItem,
		reportReasonsOptionsData
	};
};
