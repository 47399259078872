/**
 * Ids of Fields used on Node Form component.
 */
export const NodeFormFieldIds = {
	parent: "parentId",
	title: "title",
	body: "body",
	topics: "topics",
	space: "spaceId",
	coverImageType: "coverImageType",
	selectCover: "articleSelectCover",
	selectedCoverView: "articleSelectedCover",
	private: "private"
};

/**
 * Labels used on Node Form component.
 */
export const NodeFormLabels = {
	top: {
		kbentry: { pageTitleCreate: "Create an Article", pageTitleEdit: "Edit Article" },
		question: { pageTitleCreate: "Ask a Question", pageTitleEdit: "Edit a Question" }
	},
	actions: {
		previewMode: "Preview",
		sourceMode: "Source",
		selectCover: "Select Cover Image",
		submit: "Post",
		cancel: "Discard",
		updateSpace: "Update Space"
	},
	hints: [
		"Tab to the formatting toolbar with Alt/Option + F10. If inside toolbar, press ESC to return to editor."
	],
	parent: "Parent Article",
	title: "Title",
	body: "Body",
	topics: "Topics",
	space: "Space",
	selectCover: "Select Cover Image",
	selectedCoverView: "Selected Cover Image",
	private: {
		kbentry: "Make this Article Private",
		question: "Make this Question Private"
	},
	validation: {
		spaceId: "Space was not selected",
		title: "Title is required",
		body: "Body is required"
	}
};

/**
 * Placeholders used on Node Form component.
 */
export const NodeFormPlaceholders = {
    kbentry: {
        parent: "Start typing a parent Article's Title",
        title: "Start typing your Article's title",
        body: "Write your Article's content here",
        bodySourceMode: "Write your Article's HTML here",
        topics: "Select Article's topics",
        space: "Select a space for your Article"
    },
    question: {
        title: "Start typing your Question's title",
        body: "Write your Question's content here",
        bodySourceMode: "Write your Question's HTML here",
        topics: "Select Question's topics",
        space: "Select a Space for your Question"
    }
};
