import React, { useMemo } from "react";
import { Input } from "semantic-ui-react";

/**
 * Input-level action button props,
 * At this moment, supported by Semantic-UI.
 */
export interface IInputActionProps {
	icon: string;
	onClick: (event: any) => void;
	"aria-label": string;
	name: undefined | string;
	title: undefined | string;
	className: undefined | string;
}

/**
 * Controls the input properties of TextInput
 */
export interface IInputProps {
	type?: undefined | string;
	id?: undefined | string;
	placeholder?: undefined | string;
	value?: undefined | string;
	required?: undefined | boolean;
	error?: undefined | boolean;
	minLength?: undefined | number;
	maxLength?: undefined | number;
	onChange?: undefined | ((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => Promise<void> | void);
	disabled?: undefined | boolean;
	action?: undefined | IInputActionProps;
	tabIndex?: undefined | number;
}

/**
 * A wrapper component for Dropdown, within Strike app.
 * Implements Fluent UI 8 Dropdown within.
 *
 * @param props IInputProps
 * @returns React.FC<IInputProps>
 */
export const TextInput: React.FC<IInputProps> = (props) => {
	const resolvedType = useMemo(() => {
		return props.type ?? "text";
	}, [props.type]);

	return (
		<Input
			fluid
			type={resolvedType}
			id={props.id}
			placeholder={props.placeholder}
			value={props.value}
			onChange={props.onChange ?? undefined}
			className="form-control"
			required={props?.required ?? undefined}
			error={props?.error ?? undefined}
			input={{
				minLength: props?.minLength ?? undefined,
				maxLength: props?.maxLength ?? undefined
			}}
			disabled={props?.disabled ?? undefined}
			action={props.action ?? undefined}
			tabIndex={props.tabIndex ?? undefined}
			autoComplete="off"
		/>
	);
};
