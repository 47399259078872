import React, { useMemo } from "react";
import { Icon, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { IconSizeProp } from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";

/**
 * Shared Icon prop interface.
 */
export interface IBasicIconProps {
	size?: undefined | IconSizeProp;
	color?: undefined | SemanticCOLORS;
	alt?: undefined | string;
	ariaLabel?: undefined | string;
	role?: undefined | string;
	title?: undefined | string;
	bordered?: undefined | boolean;
	inverted?: undefined | boolean;
	className?: undefined | string;
	style?: undefined | React.CSSProperties;
	tabIndex?: undefined | number;
}

/**
 * Contract for the shape of the Icon rendering
 */
export interface IDefaultIconProps extends IBasicIconProps {
	name: SemanticICONS;
}

/**
 * Default ball-based Icon.
 * Helps avoid Icon rendering repetitions by
 * abstracting part of the TSX work.
 *
 * @returns React.FC
 */
export const DefaultIcon: React.FC<IDefaultIconProps> = (props) => {
	const { size, color, name, ...otherProps } = props;
	const resolvedSize = useMemo(() => size ?? "small", [size]);
	const resolvedColor = useMemo(() => color ?? "grey", [color]);
	const ariaLabel = props?.ariaLabel
		? props?.ariaLabel
		: props?.alt
		? props?.alt
		: props?.title
		? props.title
		: undefined;

	return (
		<Icon
			circular
			name={name}
			size={resolvedSize}
			color={resolvedColor}
			aria-label={ariaLabel}
			aria-hidden={ariaLabel === undefined ? "true" : "false"}
			role="img"
			tabIndex={props?.tabIndex ?? undefined}
			{...otherProps}
		/>
	);
};
