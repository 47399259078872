import React from "react";
import { useTopicsExpertsData } from "../../hooks/topic/useTopicsExperts";
import { UserPhoto } from "../common/user";
import TopicsExpertsPointsBar from "./topics.experts.pointsbar";

interface Props {
}

export const TopicsExpertsBox: React.FunctionComponent<Props> = (props) => {
    const { topThreeExperts, additionalExperts } = useTopicsExpertsData();
    return (
        topThreeExperts.length > 0 ?
            <div>
            {topThreeExperts.map((expert, index) => (
                <div key={expert.UserUPN} className="topics-experts-box-wrapper">
                    <div className="topics-experts-box-photo">
                    <UserPhoto
                        userPrincipalName={expert.UserUPN}
                        alt={`Profile: ${expert.UserName}`}
                        title={`Profile: ${expert.UserName}`}
                        size="lg"
                    />
                    </div>
                    <div className="topics-experts-box-info">
                        <p aria-label={`User name: ${expert.UserName}`} tabIndex={0}>{expert.UserName}</p> 
                        <TopicsExpertsPointsBar points={expert.Points} expertName={expert.UserName}  />
                    </div>  
                </div>  
            ))}
            {additionalExperts.length > 0 ? (
            <>
                <p className="topics-experts-additional-title" aria-label="Additional Experts" tabIndex={0}>Additional Experts</p>
                <div className="topics-experts-additional-photo-box-wrapper">
                    {additionalExperts.map((expert, index) => (
                        <UserPhoto
                            key={expert.UserUPN} 
                            userPrincipalName={expert.UserUPN}
                            alt={`Profile: ${expert.UserName}`}
                            title={`Profile: ${expert.UserName}`}
                            size="sm"
                        />
                    ))}
                </div>
            </>
        ) : null}
            </div>
        : null
    );
};