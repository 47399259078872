import React, { useEffect } from "react";
import { EventSearchForm } from "./shared/event.search.form";
import { EventSearchList } from "./shared/event.search.list";
import { Divider } from "semantic-ui-react";
import { IconButton } from "../common/button";
import { ApplicationRoutePaths } from "../router";
import { ServiceHub } from "../../service";
import { useSuperAdminAccessControl } from "../../hooks/auth/useSuperAdminAccessControl";
import { useEventForm } from "../../hooks";
import { UnauthorizedPage } from "../common/page";
import { EventsShimmer } from "../common/shimmer/events/shimmer.events";

/**
 * Component to serves as wrapper for Event Searchcomponent
 * @returns EventSearch component
 */
const EventSearchPage: React.FC = () => {
	const { isSuperAdmin, requested, requesting } = useSuperAdminAccessControl();
	const { isEventListLoading } = useEventForm();

	useEffect(()=>{
		document.title='Event Dashboard - Strike Community';
	},[])

	/**
	 * Rerouting user to Create Form
	 */
	const handleCreateEvent = () => {
		ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.eventCreate());
	};

	/**
	 * Rerouting user to Check-In Registrations Dashboard
	 */
	const handleRegistrations = () => {
		ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.eventCheckIn());
	};

	/**
	 * Loading temp component to show shimmer while list its loading
	 * @returns
	 */
	const renderLoading = () => {
		return (
			<div className={`row`}>
				<div className="col-md-12 col-lg-12 col-xl-12" tabIndex={0} aria-label="Event Form Page">
					<div className="space-manager-container">
						<EventsShimmer rows={5} showColumns />
					</div>
				</div>
			</div>
		);
	};

	/**
	 * Shows unauthorized component
	 * @returns
	 */
	const renderUnauthorized = () => {
		return <UnauthorizedPage />;
	};

	if (!requested && (requesting || isEventListLoading)) {
		return renderLoading();
	}

	if (isSuperAdmin)
		return (
			<>
				<main>
					<div className="strike-node-wrapper">
						<div className="event-dashboard-header">
							<div className="event-dashboard-header-title">
								<h1 tabIndex={0}>Events Dashboard</h1>
							</div>
							<div className="event-dashboard-header-button">
								<IconButton text="Create Event" iconName="CircleAddition" onClick={handleCreateEvent} />
								<IconButton text="Registrations" iconName="ReceiptCheck" onClick={handleRegistrations} />
							</div>
						</div>
						<Divider />
						<div>
							<EventSearchForm />
							<Divider />
							<EventSearchList />
						</div>
					</div>
				</main>
			</>
		);

	if (!isSuperAdmin) {
		return renderUnauthorized();
	}
};

export default EventSearchPage;

