import React,{ useEffect } from "react";
import { useLocation } from "react-router-dom";

export const SkipLink = () => {
    const location = useLocation();

    const handleSkipToContent = (e) => {
        e.preventDefault();
        const mainContent = document.getElementById("main-content");
        if (mainContent) {
            mainContent.setAttribute("tabindex", "-1"); 
            mainContent.focus();
        }
    };

    useEffect(() => {
        const mainContent = document.getElementById("main-content");
        if (mainContent) {
            mainContent.removeAttribute("tabindex");
        }
    }, [location.pathname]); 


    return (
        <a href="#main-content" className="skip-link" onClick={handleSkipToContent}>
            Skip to main content
        </a>
    );
};
