import React, { useEffect, useRef, useState } from "react";
import { BookIcon } from "../common/icon";
import { NodeAPIServiceInstance } from "../../service/NodeAPI";
import { Link, useLocation, useParams } from "react-router-dom";
import { ApplicationRoutePaths } from "../router";
import { ServiceHub } from "../../service";
import { useSelector } from "react-redux";
import { appManagementSelectors } from "../../redux/selectors/appManagement";
interface Props {
	className?: string;
}
/**
 * Sticky Nodes component to be used in the application Dashboards
 */
export const StickyNodes: React.FC<Props> = (props) => {
	const params = useParams();
	const location = useLocation();
	const stickyNodes = useSelector(appManagementSelectors.getStickyNodes);
	const [isLoaded, setIsLoaded] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		setIsLoaded(false);
		ServiceHub.appManagementAPI.resetStickyNodes()
		setIsLoading(true)
		getStickyNodes();
	}, [location]);

	const getStickyNodes = async () => {
		ServiceHub.appManagementAPI.resetStickyNodes()
		const res = await NodeAPIServiceInstance.getStickyNodes(params.id);
		if (res) {
			ServiceHub.appManagementAPI.setStickyNodes(res)
			setIsLoaded(true);
			setIsLoading(false)
		}
	};

	return isLoaded && !isLoading && stickyNodes.length > 0 ? (
		<div className={`dashboard-sticky-nodes-container ${props.className}`}>
			{stickyNodes.map((node) => {
					return (
						<div className="strike-headline-link" key={node.NodeId}>
							<BookIcon size="tiny" inverted title={"Article"} />
							<Link to={ApplicationRoutePaths.article(node.NodeId.toString(), node.Title)} title={node.Title}>
								{" "}
								<span>{node.Title}</span>
							</Link>
						</div>
					);
				})}
		</div>
	) : null;
};
