import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tagsSelectors } from "../../../redux/selectors/tags";
import { NodeType, TagsModeType } from "../../../contracts/models/strikeEnums";
import { NodeTopicsDisplay } from "../node/topics/nodeTopics.display";
import { IconButton } from "../button";
import { setTagsMode } from "../../../redux/reducers/tags";
import { NodeAPIServiceInstance } from "../../../service/NodeAPI";
import { useEffect } from "react";
import { useState } from "react";
import { CustomMultiSelect } from "../select/multiSelect";
import { useNodeAdminAccessControl, useNodeOwner } from "../../../hooks";
import { useMemo } from "react";
import { ServiceHub } from "../../../service";
import { useRef } from "react";
import { DropdownItemProps } from "semantic-ui-react";

/**
 * Controls to show or edit tags
 */
export interface IShowEditTagsProps {
	nodeId: string;
	type?: NodeType;
	authorUpn?: string;
	onComplete?: () => void;
}

interface Topic {
	key: string;
	text: string;
	value: string;
}

const strings = {
	button: {
		ariaLabel: {
			addTopics: "Action: Add a Topic",
			changeTopics: "Action: Change Topics",
			saveTopics: "Action: Confirm changes"
		},
		text: {
			addTopics: "Add Topic",
			changeTopics: "Edit",
			saveTopics: "Save"
		},
		icon: {
			addTopics: "AddTo",
			changeTopics: "EditMirrored",
			saveTopics: "Save"
		}
	}
};

/**
 * Reusable component to show or edit tags in a node
 * Currently included at node detail level Question or Kbentry.
 */
export const ShowEditTags: React.FC<IShowEditTagsProps> = (props) => {
	const dispatch = useDispatch();
	const nodeAdminAccessControl = useNodeAdminAccessControl();
	const topicsAPI = useRef(ServiceHub.topicsAPI.start());
	const isUserOwner = useNodeOwner();
	const tagsMode = useSelector(tagsSelectors.getTagsMode);
	const TopicsNames = useSelector(tagsSelectors.getTagsNames);
	const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
	const [options, setOptions] = useState<Topic[]>([]);
	const [addedTopics, setAddedTopics] = useState<string[]>([]);

	const resolvedNodeOwner = isUserOwner({
		Id: !props.nodeId ? 0 : parseInt(props.nodeId),
		AuthEmail: props.authorUpn
	});

	const canAddTopics = useMemo(() => {
		return nodeAdminAccessControl.canAccess || resolvedNodeOwner;
	}, [nodeAdminAccessControl, resolvedNodeOwner]);

	const resolvedKeys = useMemo(() => {
		const finalKeys = new Set();

		if (addedTopics.length > 0) {
			addedTopics.forEach((topic) => finalKeys.add(topic));
		}

		if (options.length > 0) {
			options.forEach((topic) => finalKeys.add(topic.key));
		}

		return Array.from(finalKeys);
	}, [addedTopics, options]);

	const resolvedOptions = useMemo<DropdownItemProps[]>(() => {
		return resolvedKeys.map((topic) => ({ key: topic, text: topic, value: topic } as DropdownItemProps));
	}, [resolvedKeys]);

	const isAlreadySelected = useCallback(
		(optionKey: string) => {
			return selectedTopics.includes(optionKey);
		},
		[selectedTopics]
	);

	function onEditMode() {
		dispatch(setTagsMode(TagsModeType.Edit));
		setSelectedTopics(TopicsNames);
	}

	async function onSave() {
		dispatch(setTagsMode(TagsModeType.View));

		if (addedTopics.length > 0) {
			await topicsAPI.current.create(addedTopics);
			setAddedTopics([]);
		}

		await topicsAPI.current.updateNodeTopics(parseInt(props.nodeId), selectedTopics).then((result) => {
			if (result) {
				getNodesAllTopics();

				if (typeof props.onComplete === "function") {
					props?.onComplete();
				}
			}
		});
	}

	function handleTopicEdit(event, data) {
		let tagValues = data.value as string[];

		tagValues = tagValues.reduce((prev, curr) => {
			return prev.length === 0
				? [curr.toUpperCase()]
				: !prev.includes(curr.toUpperCase())
				? [...prev, curr.toUpperCase()]
				: prev;
		}, []);

		setSelectedTopics(tagValues);

		// Handle the case if any added topic was in fact removed from selected,
		// Also removes from those being added to the list to avoid unnecessary data posting
		if (tagValues.length > 0 && addedTopics.length > 0) {
			const filteredAddedTopics = addedTopics.filter((addedTopic) => tagValues.includes(addedTopic));
			setAddedTopics(filteredAddedTopics);
		}
	}

	function handleAddTopic(event, data) {
		const newTopic = data.value.toString().toUpperCase();

		if (isAlreadySelected(newTopic)) {
			event.stopPropagation();
			return;
		}

		setSelectedTopics((prevSelected) =>
			prevSelected.includes(newTopic) ? prevSelected : [...prevSelected, newTopic]
		);
		setAddedTopics((prevNewItems) => [...prevNewItems, newTopic]);
	}

	const getNodesAllTopics = async () => {
		let nodeTopics = [];
		let res = await NodeAPIServiceInstance.getNodesAllTopics();
		if (res) {
			res.map((item) => {
				nodeTopics.push({ key: item, text: item, value: item });
			});
			setOptions(nodeTopics);
		}
	};

	useEffect(() => {
		getNodesAllTopics();
		dispatch(setTagsMode(TagsModeType.View));
	}, []);

	useEffect(() => {
		if (selectedTopics) {
			const editTopicsInput = document.querySelector('.show-edit-tags-edit-mode-select-container input');
			if (editTopicsInput) {
				editTopicsInput.setAttribute('title', 'Select Topics');
			}
		}
	}, [selectedTopics]);

	return canAddTopics ? (
		<div>
			{tagsMode === TagsModeType.View && TopicsNames && TopicsNames[0] === "" ? (
				<div className="show-edit-tags-view-mode">
					<IconButton
						onClick={onEditMode}
						className="show-edit-tags-view-mode-edit-button"
						iconName={strings.button.icon.addTopics}
						text={strings.button.text.addTopics}
						ariaLabel={strings.button.ariaLabel.addTopics}
					/>
				</div>
			) : tagsMode === TagsModeType.View ? (
				<div className="show-edit-tags-edit-mode">
					<div className="show-edit-tags-edit-mode-select-container">
						<NodeTopicsDisplay />
					</div>
					<div className="show-edit-tags-button-wrapper">
						<IconButton
							onClick={onEditMode}
							className="show-edit-tags-view-mode-edit-button"
							iconName={strings.button.icon.changeTopics}
							text={strings.button.text.changeTopics}
							ariaLabel={strings.button.ariaLabel.changeTopics}
						/>
					</div>
				</div>
			) : (
				<div className="show-edit-tags-edit-mode">
					<div className="show-edit-tags-edit-mode-select-container">
						<CustomMultiSelect
							handleItemEdit={handleTopicEdit}
							handleAddItem={handleAddTopic}
							options={resolvedOptions}
							selectedOptions={selectedTopics}
							canAdd={props.type === NodeType.Kbentry ? true : false}
						/>
					</div>
					<div className="show-edit-tags-button-wrapper">
						<IconButton
							onClick={onSave}
							className="show-edit-tags-view-mode-save-button"
							iconName={strings.button.icon.saveTopics}
							text={strings.button.text.saveTopics}
							ariaLabel={strings.button.ariaLabel.saveTopics}
						/>
					</div>
				</div>
			)}
		</div>
	) : (
		<NodeTopicsDisplay />
	);
};
