import React from "react";
import { Header, Icon, SemanticICONS } from "semantic-ui-react";
import { SpaceTypes, TitleType } from "../../contracts/models/strikeEnums";
import { Shimmer } from "../common/shimmer";
import { PageHeaderActions } from "./shared/header.actions";
import { useLocation } from "react-router-dom";
import { formatURLQueryString } from "../../lib/strikeLibrary";
import { ServiceHub } from "../../service";
import { useShareContent } from "../../hooks";

/**
 * Interface for controlling the Page Header properties.
 */
interface IPageHeaderProps {
	Title: string;
	Type: TitleType;
	showActions?: undefined | boolean;
	isOwner?: undefined | boolean;
	onEdit?: () => void | Promise<void>;
	divided?: undefined | boolean;
	enableShareContent?: undefined | boolean;
	onDelete?: () => void | Promise<void>;
	onChangeSpace?: () => void | Promise<void>;
	onStickySpace?: () => void | Promise<void>;
	onStickyWebsite?: () => void | Promise<void>;
	onMakePrivate?: () => void | Promise<void>;
	onExportToPdf?: () => void | Promise<void>;
	onNodeRevision?: () => void | Promise<void>;
	onReportContent?: () => void | Promise<void>;
	onSendNodeToModeration?: () => void | Promise<void>;
}

/**
 * Page Header component.
 *
 * @param props IPageHeaderProps
 * @returns React.FunctionComponent<IPageHeaderProps>
 */
export const PageHeader: React.FunctionComponent<IPageHeaderProps> = (props) => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const urlTopics = queryParams.get("topics");
	const formattedTopicTitle = urlTopics ? formatURLQueryString(urlTopics) : "";
	const selectedSpaceId = ServiceHub.spacesAPI.start().getSelectedId();
	const shareContent = useShareContent();

	/**
	 * Enables the Share Content panel,
	 * By setting it to open => true.
	 */
	const onShareContent = () => {
		shareContent.onOpen();
	};

	const getAriaLabel = (): string => {
		return "Title: " + props?.Title ? props.Title : "All Posts";
	};

	const getIconAriaLabel = (): string => {
		switch (props.Type) {
			case TitleType.KbentryDashboard:
				return "Articles' Dashboard";
			case TitleType.FavoritesDashboard:
				return "Favorites' Dashboard";
			case TitleType.AllUnansweredDashboard:
				return "Unanswered' Dashboard";
			case TitleType.MyActivityDashboard:
				return "Activity' Dashboard";
			case TitleType.MyFollowersDashboard:
				return "Followers' Dashboard";
			case TitleType.MyUnansweredDashboard:
				return "My Unanswered' Dashboard";
			case TitleType.Kbentry:
				return "Viewing Article";
			case TitleType.Question:
				return "Viewing Question";
			case TitleType.QuestionDashboard:
				return "Questions' Dashboard";
			case TitleType.Event:
				return "Viewing Event";
			case TitleType.Dashboard:
				return "Main Dashboard";
			default:
				return "Viewing File";
		}
	};

	const getIconClass = (): SemanticICONS => {
		switch (props.Type) {
			case TitleType.Kbentry:
			case TitleType.KbentryDashboard:
				return "file alternate outline";
			case TitleType.Question:
			case TitleType.QuestionDashboard:
				return "question circle outline";
			case TitleType.Event:
				return "calendar alternate outline";
			case TitleType.Dashboard:
			case TitleType.AllUnansweredDashboard:
				return "list";
			case TitleType.FavoritesDashboard:
				return "heart outline";
			case TitleType.AllUnansweredDashboard:
				return "list";
			case TitleType.MyActivityDashboard:
				return "clipboard outline";
			case TitleType.MyFollowersDashboard:
				return "user outline";
			case TitleType.MyUnansweredDashboard:
				return "list";
			default:
				return "file alternate";
		}
	};

	return (
		<div className="page-header-container">
			<div className="page-header-icon">
				<Icon name={getIconClass()} size="big" aria-label={getIconAriaLabel()} role="img" />
			</div>
			<div className="page-header-container-head-line">
				<div
					className={`page-header-text ${props?.divided ? "divided" : ""}`}
					aria-label={getAriaLabel()}
				>
					<Header as="h1" className="page-header-container-head-line">
					<Header.Content>
					{[TitleType.Dashboard].includes(props.Type) ? (
						props.Title ? (
							"All Posts in " + props.Title
						) : formattedTopicTitle && selectedSpaceId === SpaceTypes.OnlineCourses ? (
							"All Posts in " + formattedTopicTitle
						) : (
							"All Posts"
						)
					) : !props.Title || props.Title === "" ? (
						<Shimmer width="250" height="20" />
					) : (
						props.Title
					)}
					</Header.Content>
					</Header>
				</div>
				{props?.showActions ? (
					<div className="page-header-actions">
						<PageHeaderActions
							Type={props.Type}
							enabled={props?.showActions ?? undefined}
							isOwner={props?.isOwner ?? false}
							onEdit={props?.onEdit}
							onDelete={props?.onDelete}
							onChangeSpace={props?.onChangeSpace}
							onStickySpace={props?.onStickySpace}
							onStickyWebsite={props?.onStickyWebsite}
							onExportToPdf={props?.onExportToPdf}
							onShareContent={!props?.enableShareContent ? undefined : onShareContent}
							onMakePrivate={props?.onMakePrivate}
							onNodeRevision={props?.onNodeRevision}
							onReportContent={props?.onReportContent}
							onSendNodeToModeration={props?.onSendNodeToModeration}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default PageHeader;
