import React, { useMemo, useRef, useState } from "react";
import { ICommandBarItemProps, IContextualMenuItem } from "@fluentui/react";
import { ContextMenu } from "../../contextMenu";
import { NodeActionTypes, NodeCommentStatus, NodeType } from "../../../../contracts/models/strikeEnums";
import { ServiceHub } from "../../../../service";
import { useSelector } from "react-redux";
import { commentsSelectors } from "../../../../redux/selectors/comments";
import { useAuthUser, useHtmlVirtualization, useNodeActionsSlice, useNodeOwner } from "../../../../hooks";
import { useParams } from "react-router-dom";
import { useSuperAdminAccessControl } from "../../../../hooks/auth/useSuperAdminAccessControl";
import { nodesSelectors } from "../../../../redux/selectors/nodes";
import { ConfirmationMessage } from "../../message";
import { IArticleFormData } from "../../../../contracts/article/articleForm";
import { IStrikeNodesComments } from "../../../../contracts/models";
import { CustomPanel } from "../../panel/Panel";
import { NodeReportPanel } from "../report/node.report";

/**
 * Node Activity Context menu builder props.
 */
export interface INodeActivityContextMenuProps {
	nodeId: number;
	nodeType: string | NodeType;
	disabled?: undefined | boolean;
}

/**
 * Standard Node Activity context menu builder.
 *
 * @param props INodeActivityContextMenuProps
 * @returns React.FC<INodeActivityContextMenuProps>
 */
export const NodeActivityContextMenu: React.FC<INodeActivityContextMenuProps> = (props) => {
	const svc = ServiceHub.commentAPI.start();
	const nodeById = useSelector((state) => commentsSelectors.getEditingItemById(state, props.nodeId));
	const nodeTypeName = useMemo(() => {
		let _nodeName = props.nodeType;

		switch (props?.nodeType) {
			case NodeActionTypes.Answer: {
				_nodeName = "Answer";
				break;
			}
			case NodeActionTypes.Comment: {
				_nodeName = "Comment";
				break;
			}
			case NodeActionTypes.CommentToAnswer:
			case NodeActionTypes.AnswerToComment:
			default: {
				_nodeName = "Reply";
				break;
			}
		}

		return _nodeName;
	}, [props.nodeType]);

	const nodeIsNotDeleted = useMemo(() => {
		return nodeById.Title !== NodeCommentStatus.DeletedComment;
	}, [nodeById]);

	const params = useParams();
	const commentSvc = useRef(ServiceHub.commentAPI.start());
	const commentText = useSelector(commentsSelectors.getCommentText);
	const virtualHtml = useHtmlVirtualization(commentText);
	const authUser = useAuthUser();
	const nodeAuthorEmail = useSelector((state) => nodesSelectors.getNodeDetailAuthorEmail(state));
	
	const isNodeOwner = useNodeOwner();
	const resolvedNodeOwner = isNodeOwner({ Id: parseInt(params.id), AuthEmail: nodeAuthorEmail });
	const { isSuperAdmin } = useSuperAdminAccessControl();
	const [isOpenReportCommentConfirmation, setIsOpenReportCommentConfirmation] = useState(false);
	const [isOpenDeleteCommentConfirmation, setIsOpenDeleteCommentConfirmation] = useState(false)

	// Revisions hooked data
	const NodeActions = useNodeActionsSlice();
	const openRevisionsPanel = NodeActions.openRevisionsPanel;

	/**
	 * Check if user is Comment Owner
	 */
	const isOwner = useMemo(() => {
		return authUser && authUser.userPrincipalName === nodeById.CommentByEmail;
	}, [authUser, nodeById]);

	/** 
	* Check restriction to show Delete Comment action
	*/
	const canDeleteComment = useMemo(() => {
		return isOwner || isSuperAdmin || resolvedNodeOwner;
	}, [isOwner, isSuperAdmin, resolvedNodeOwner]);

	/**
	 * Handling Close action for Report Comment Modal Confirmation
	 */
	const onCloseReportCommentConfirmation = (ev) => {
		const answerId = document.getElementById(`${props.nodeId}`)
		if (ev.key === 'Escape') {
			const selectReasonDropdown = document.querySelector('.tree-view-dropdown-selected-item');
			const selectReasonDropdownOpen = document.querySelector('.tree-view-dropdown-selected-item[aria-expanded="true"]');
			if (selectReasonDropdownOpen) {
			selectReasonDropdown.setAttribute('aria-expanded', 'false')
			} else {
			setIsOpenReportCommentConfirmation(false);
			if(answerId) {
				answerId.querySelector<HTMLButtonElement>('.node-activity-more-actions-menu button').focus();
			}
			}
		} else {
			setIsOpenReportCommentConfirmation(false);
			if(answerId) {
				answerId.querySelector<HTMLButtonElement>('.node-activity-more-actions-menu button').focus();
			}
		}
	
	}

	/**
	 * Handling Close action for Delete Comment Modal Confirmation
	 */
	const onCloseDeleteCommentConfirmation = () => {
		setIsOpenDeleteCommentConfirmation(false)
	}

	/**
	* query Model to pass Form Comment Data
	*/
	function getEditQueryFormCommentData(id: string, rootId: string, _parentId: string, body: string): IArticleFormData {
		const response = {
			id: id,
			parentId: _parentId,
			commentNodeId: rootId,
			body: body
		};

		return response as IArticleFormData;
	}

	function onDeleteCommentConfirmation() {
		const itemToDelete = svc.getEditingItemById(props.nodeId);
		const commentFormData: IArticleFormData = getEditQueryFormCommentData(itemToDelete.CommentId.toString(), params.id, itemToDelete.ParentId.toString(), NodeCommentStatus.DeletedComment);
		let serviceCall: Promise<IArticleFormData>;

		switch (props.nodeType) {
			case NodeActionTypes.Comment: {
				serviceCall = ServiceHub.articleDataAPI.start().createComment(commentFormData);
				break;
			}
			case NodeActionTypes.Answer: {
				serviceCall = ServiceHub.articleDataAPI.start().createAnswer(commentFormData);
				break;
			}
			case NodeActionTypes.CommentToAnswer: {
				serviceCall = ServiceHub.articleDataAPI.start().createCommentToAnswer(commentFormData);
				break;
			}
			case NodeActionTypes.AnswerToComment: {
				serviceCall = ServiceHub.articleDataAPI.start().createAnswerToComment(commentFormData);
				break;
			}
			default: {
				break;
			}
		}

		serviceCall
		.then((added: any) => {
			if (!added.Id) {
				throw Error("An error occurred while posting the comment");
			}
			setIsOpenDeleteCommentConfirmation(false);
			const commentElement = document.getElementById(added.Id);
			if (commentElement) {
				const textContainerDiv = commentElement.querySelector('.node-activity-comment-text') as HTMLElement;
				if (textContainerDiv) {
					const textContainer = textContainerDiv.lastElementChild as HTMLElement;
					textContainer.textContent = NodeCommentStatus.DeletedCommentString;
					textContainer.classList.add('deleted-comment');
				}
			}
				(async () => {
					await commentSvc.current.setCommentById(added.Id, {
						Title: added.Body
					} as IStrikeNodesComments);
					await commentSvc.current.resetEditingIds();
					virtualHtml.onClearAll();
				})();
			})
	}


	const strings = {
		button: {
			icon: {
				more: {
					name: "More"
				},
				edit: {
					name: "Edit"
				},
				report: {
					name: "Flag"
				},
				delete: {
					name: "Delete"
				},
				revisions: {
					name: "History"
				}
			},
			more: {
				key: "moreActions",
				ariaLabel: `More ${nodeTypeName} Actions`
			},
			edit: {
				key: `Edit${nodeTypeName}`,
				ariaLabel: `Edit this ${nodeTypeName}`
			},
			report: {
				key: `Report${nodeTypeName}`,
				ariaLabel: `Report this ${nodeTypeName}`
			},
			revisions: {
				key: `Revisions`,
				ariaLabel: `Revision this ${nodeTypeName}`
			},
			delete: {
				key: `Delete${nodeTypeName}`,
				ariaLabel: `Delete this ${nodeTypeName}`
			}
		},
		contextMenu: {
			ariaLabel: `${nodeTypeName} actions`
		}
	};

	const editCommentAction = {
		key: strings.button.edit.key,
		ariaLabel: strings.button.edit.ariaLabel,
		text: `Edit ${nodeTypeName}`,
		iconProps: {
			iconName: strings.button.icon.edit.name
		},
		onClick: (
			ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
			item?: IContextualMenuItem
		) => {
			const svc = ServiceHub.commentAPI.start();

			const editingItem = svc.getEditingItemById(props.nodeId);

			svc.setEditingIds([props.nodeId]);
			svc.setFormText(editingItem.Title);
		}
	};

	const deleteCommentAction = {
		key: strings.button.delete.key,
		ariaLabel: strings.button.delete.ariaLabel,
		text: `Delete ${nodeTypeName}`,
		iconProps: {
			iconName: strings.button.icon.delete.name
		},
		onClick: (
			ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
			item?: IContextualMenuItem
		) => {
			setIsOpenDeleteCommentConfirmation(true);
			ServiceHub.appManagementAPI.setConfirmationCallback(onDeleteCommentConfirmation);
		}
	};

	const revisionCommentAction = {
		key: strings.button.revisions.key,
		ariaLabel: strings.button.revisions.ariaLabel,
		text: `Revisions`,
		iconProps: {
			iconName: strings.button.icon.revisions.name
		},
		onClick: (
			ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
			item?: IContextualMenuItem
		) => {
			NodeActions.onGetNodeRevisions(props.nodeId.toString());
			openRevisionsPanel();
		}
	};

	const reportCommentAction = {
		key: strings.button.report.key,
		ariaLabel: strings.button.report.ariaLabel,
		text: `Report ${nodeTypeName}`,
		iconProps: {
			iconName: strings.button.icon.report.name
		},
		onClick: (
			ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
			item?: IContextualMenuItem
		) => {
			setIsOpenReportCommentConfirmation(true)
			ServiceHub.appManagementAPI.resetReportReasonValueId();
		}
	};

	const items: ICommandBarItemProps[] = [
		{
			key: strings.button.more.key,
			cacheKey: strings.button.more.key,
			ariaLabel: strings.button.more.ariaLabel,
			disabled: props?.disabled ?? false,
			text: "More",
			subMenuProps: {
				items: [
					...(isOwner && nodeIsNotDeleted ? [editCommentAction] : []),
					...([reportCommentAction]),
					...(canDeleteComment && nodeIsNotDeleted ? [deleteCommentAction] : []),
					...(canDeleteComment ? [revisionCommentAction] : []),
				]
			}
		}
	];

	return (
		<>
			{ canDeleteComment ? <ContextMenu items={items} ariaLabel={strings.contextMenu.ariaLabel} /> : null }
			<CustomPanel
				isOpen={isOpenReportCommentConfirmation}
				onDismiss={onCloseReportCommentConfirmation}
				headerText="Report Comment"
				>
				<NodeReportPanel NodeId={props.nodeId} isComment setOpenReportPanel={setIsOpenReportCommentConfirmation} />
			</CustomPanel>
			<ConfirmationMessage
				isOpen={isOpenDeleteCommentConfirmation}
				onClose={onCloseDeleteCommentConfirmation}
				onAction={ServiceHub.appManagementAPI.getConfirmationCallback()}
				title={NodeCommentStatus.DeletedCommentConfirmationTitle}
				text={NodeCommentStatus.DeletedCommentConfirmationText}
			/>
		</> 
	)
};
