import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { IconButton } from "../../../common/button";
import { DivEventHandler, InputTextEventHandler, RichTextInput, TextInput } from "../../../common/text";
import { FormActions, FormContainer, FormField } from "../../../common/form";
import { ApplicationRoutePaths } from "../../../router";
import { NodeFormLabels } from "../node.form.strings";
import { TreeViewDropdown } from "../../dropdown";
import { useSpaceForm } from "../../../../hooks/space/useSpaceForm";
import { useRef } from "react";
import { ServiceHub } from "../../../../service";
import { useEffect } from "react";
import { useMemo } from "react";
import { SpaceFormLabels, SpaceFormPlaceholders } from "./space.form.strings";
import { useSelector } from "react-redux";
import { spaceFormSelectors } from "../../../../redux/selectors/spaceForm";
import { CoverImageType } from "../../../../enums";
import { useParams } from "react-router-dom";
import { useAuthUser } from "../../../../hooks";
import { UnauthorizedPage } from "../../page";
import { FormShimmer } from "../../shimmer";
import { useState } from "react";
import { useSuperAdminAccessControl } from "../../../../hooks/auth/useSuperAdminAccessControl";

/**
 * Contract for the input props of the NodeForm.
 */
export interface IFormSpaceProps {
	onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void> | void;
}

/**
 * Space creation form
 *
 * @returns React.FC
 */
export const FormSpace: React.FC<IFormSpaceProps> = (props) => {
	const navigate = useNavigate();
	const { parentSpaces, descriptionBodySourceMode, bannerBodySourceMode, descriptionVirtualHTML, bannerVirtualHTML, spaceFormData, isNewSpace, canSubmit, isFormDataLoaded, isFormDataLoading, coverImageFile } =
		useSpaceForm();
	const spaceFormService = useRef(ServiceHub.spaceForm.start());
	const spacesFormDataAPI = useRef(ServiceHub.spaceAdminAPI.start());
	const selectedParentId = useSelector(spaceFormSelectors.getFormSelectedItem);
	const isSpaceFormLoading = useSelector(spaceFormSelectors.getIsLoading);
	const spaceImageRef = useRef<HTMLInputElement>(null);
	const submitDisabled = useMemo(() => !canSubmit, [canSubmit]);
	const { userPrincipalName } = useAuthUser();
	const params = useParams();
	const location = useLocation();
	const { isSuperAdmin, requested, requesting } = useSuperAdminAccessControl();
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const editorDescriptionModeRef = useRef(null);
	const editorBannerModeRef = useRef(null);

	/**
	 * Generic method to set specific space form property value
	 */
	const onSetStateProp = useCallback(
		function (prop: string, value: any) {
			spaceFormService.current.setData({
				...spaceFormData,
				[prop]: value
			});
		},
		[spaceFormService, spaceFormData]
	);

	/**
	 * Managing for input change value
	 */
	const onChange = useCallback(
		(prop: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (typeof event?.preventDefault === "function") event.preventDefault();

			onSetStateProp(prop, typeof event === "string" ? event : event.currentTarget?.value);
		},
		[onSetStateProp]
	);

	/**
	 * Handle form discard button
	 * @param event
	 * @returns
	 */
	function onCancel(event: React.FormEvent<HTMLButtonElement>) {
		return navigate(ApplicationRoutePaths.spaceManage());
	}

	/**
	 * Used on input to select coverImage from HD
	 * @param event
	 */
	const onHandleSpaceImage = (event) => {
		if (spaceImageRef.current) {
			spaceImageRef.current.click();
		}
	};

	/**
	 * Setting coverImage properties on prep for upload
	 */
	const onUploadSpaceImage = useCallback(
		(event: React.FormEvent<HTMLInputElement>) => {
			const { files } = event.currentTarget;

			if (!files || files.length === 0) return;

			const fileUnit = files[0];
			const reader: FileReader = new FileReader();

			reader.onload = function (this: FileReader, ev: ProgressEvent<FileReader>) {
				spaceFormService.current.setData({
					...spaceFormData,
					coverImageType: CoverImageType.Upload,
					coverImageFile: reader.result.toString(),
					coverImageMimeType: fileUnit.type,
					coverImageSize: fileUnit.size,
					coverImageName: fileUnit.name,
					coverImageId: ""
				});
				spaceFormService.current.setCoverImageFile(reader.result.toString());
			};

			setIsImageLoaded(true);

			reader.readAsDataURL(fileUnit);
		},
		[spaceFormData, spaceFormService]
	);

	/**
	 * Handling change on Parent space
	 * @param item
	 */
	const handleSetParentId = (item) => {
		spaceFormService.current.setData({
			...spaceFormData,
			parentId: item.id
		});
		ServiceHub.spaceForm.start().setSelectedParentId(item.id);
	};

	/**
	 * Render unauthorized component based on access
	 * @returns
	 */
	const renderUnauthorized = () => {
		return <UnauthorizedPage />;
	};

	/**
	 * Utility to convert image string path to data image
	 * @param imagePath
	 */
	const getCoverImageData = (imagePath: string) => {
		ServiceHub.appFileAPI
			.start()
			.getCoverImageByURL(imagePath)
			.then((result) => {
				const imageData = result ? URL.createObjectURL(result) : null;
				spaceFormService.current.setCoverImageFile(imageData);
				setIsImageLoaded(true);
			});
	};

	useEffect(() => {
		spaceFormService.current.setData({
			...spaceFormData,
			authorUpn: userPrincipalName
		});

		if (location.pathname === ApplicationRoutePaths.spaceCreate()) {
			spaceFormService.current.resetData();
			spaceFormService.current.resetSelectedParentId();
		} else {
			if (params.id) {
				spaceFormService.current.setIsSpaceFormLoading(true);
				spaceFormService.current.setIsSpaceFormDataLoading(true);
				spaceFormService.current.setIsSpaceFormDataLoaded(false);
				spacesFormDataAPI.current
					.getSpace(params.id)
					.then((result: any) => {
						if (result instanceof Error) {
							ServiceHub.message.error(result.message);
							throw result;
						}
						getCoverImageData(result.Value.CoverImagePath);
						const preparedId = spaceFormService.current.getSpaceById(params.id);
						spaceFormService.current.setSelectedParentId(preparedId.ParentId.toString());
						spaceFormService.current.setData({
							...spaceFormData,
							id: result.Value.Id,
							name: result.Value.Name,
							parentId: result.Value.ParentId,
							description: result.Value.Description ? result.Value.Description : null,
							banner: result.Value.Banner ? result.Value.Banner : null,
							coverImageName: result.Value.CoverImagePath
						});
					})
					.finally(() => {
						spaceFormService.current.setIsSpaceFormLoading(false);
						spaceFormService.current.setIsSpaceFormDataLoading(false);
						spaceFormService.current.setIsSpaceFormDataLoaded(true);
					});
			}
		}

		window.scrollTo(0, 0);
	}, [params.id, location]);

	/**
	 * Handling RTE input text change
	 */
	const onDescriptionChangeGeneric = useCallback(
		(prop: string) => (event: React.FocusEvent<HTMLElement>) => {
			onSetStateProp(prop, typeof event === "string" ? event : event.currentTarget.innerText);
			// When setting this state, clearing the errors to reprocess them
			descriptionVirtualHTML.onClearErrors();
		},
		[onSetStateProp, descriptionVirtualHTML.onClearErrors]
	);

	/**
	 * Handling RTE input html change
	 */
	const onDescriptionChangeVirtualHtml = useCallback(
		(prop: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (typeof event?.preventDefault === "function") event.preventDefault();
			onSetStateProp(prop, typeof event === "string" ? event : event.currentTarget?.value);
			descriptionVirtualHTML.onChange(typeof event === "string" ? event : event.currentTarget?.value);
		},
		[descriptionVirtualHTML]
	);

	/**
 * Handling RTE input text change
 */
	const onBannerChangeGeneric = useCallback(
		(prop: string) => (event: React.FocusEvent<HTMLElement>) => {
			onSetStateProp(prop, typeof event === "string" ? event : event.currentTarget.innerText);
			// When setting this state, clearing the errors to reprocess them
			bannerVirtualHTML.onClearErrors();
		},
		[onSetStateProp, bannerVirtualHTML.onClearErrors]
	);

	/**
	 * Handling RTE input html change
	 */
	const onBannerChangeVirtualHtml = useCallback(
		(prop: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (typeof event?.preventDefault === "function") event.preventDefault();
			onSetStateProp(prop, typeof event === "string" ? event : event.currentTarget?.value);
			bannerVirtualHTML.onChange(typeof event === "string" ? event : event.currentTarget?.value);
		},
		[bannerVirtualHTML]
	);

	/**
	 * Sets the Space Description Body writing mode,
	 * based on the a currentTarget.value
	 *
	 * @param event
	 */
	function onSetDescriptionBodySourceMode(event: React.FormEvent<HTMLButtonElement>) {
		const value = event.currentTarget.value === "true";

		spaceFormService.current.setDescriptionBodySourceMode(value);

		editorDescriptionModeRef.current.focus();
	}

	/**
	 * Sets the Spac Banner Body writing mode,
	 * based on the a currentTarget.value
	 *
	 * @param event
	 */
	function onSetBannerBodySourceMode(event: React.FormEvent<HTMLButtonElement>) {
		const value = event.currentTarget.value === "true";

		spaceFormService.current.setBannerBodySourceMode(value);

		editorBannerModeRef.current.focus();
	}

	if (isSuperAdmin)
		return (
			<div className={`row`}>
				<div className="col-md-12 col-lg-6 col-xl-8" tabIndex={0} aria-label="Form Page">
					{isSpaceFormLoading ? (
						<FormShimmer />
					) : (
						<FormContainer
							onSubmit={props?.onSubmit ?? undefined}
							title={isNewSpace ? SpaceFormLabels.top.pageSpaceCreate : SpaceFormLabels.top.pageSpaceEdit}
						>
							<FormField id="Name" label="Name" required>
								<TextInput
									id="Name"
									placeholder={SpaceFormPlaceholders.space.name}
									onChange={onChange("name")}
									value={spaceFormData.name}
									required
									maxLength={200}
								/>
							</FormField>
							<FormField id="Parent Space" label="Parent Space" required>
								<TreeViewDropdown
									id="Parent Space"
									options={parentSpaces}
									onChange={handleSetParentId}
									placeholder={SpaceFormPlaceholders.space.parentSpace}
									selectedItem={selectedParentId}
								/>
							</FormField>

							<FormField id="Space Image" label="Space Image">
								<IconButton ariaLabel="Select Space Image" iconName="ImageSearch" text="Select Image" onClick={onHandleSpaceImage} />
								<input
									className="visibility-hidden"
									ref={spaceImageRef}
									id="spaceImage"
									type="file"
									onChange={onUploadSpaceImage}
								/>
							</FormField>
							{coverImageFile && isImageLoaded ? (
								<div className="space-manager-image-display">
									<img src={coverImageFile} alt="Space Image" width="200" height="100" />
								</div>
							) : null}
							<FormField id="Description">
								<div className="form-field-header">
									<label htmlFor="Description" aria-label="Description">
										Description
									</label>
									<div className="button-group">
										<button
											type="button"
											onClick={onSetDescriptionBodySourceMode}
											value={"false"}
											className={`${!descriptionBodySourceMode ? "selected" : ""}`}
											title={`Preview mode ${!descriptionBodySourceMode ? "selected" : ""}`}
										>
											Preview
										</button>
										<button
											type="button"
											onClick={onSetDescriptionBodySourceMode}
											value={"true"}
											className={`${descriptionBodySourceMode ? "selected" : ""}`}
											title={`Source mode ${descriptionBodySourceMode ? "selected" : ""}`}
										>
											Source
										</button>
									</div>
									<div
										ref={editorDescriptionModeRef}
										role="alert"
										aria-live="polite"
										className="notification"
										style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}
									>
										{descriptionBodySourceMode ? 'Source mode selected' : 'Preview mode selected'}
									</div>
								</div>
								<div className="form-control event-description-field">
									<RichTextInput
										id="Description"
										required={true}
										placeholder={
											descriptionBodySourceMode
												? "Write your event details description HTML here..."
												: "Write your event description content here..."
										}
										onChange={
											descriptionBodySourceMode
												? (onDescriptionChangeGeneric("description") as DivEventHandler)
												: (onDescriptionChangeVirtualHtml("description") as InputTextEventHandler)
										}
										value={!descriptionBodySourceMode ? descriptionVirtualHTML.virtualHtml : descriptionVirtualHTML.originalHtml}
										sourceMode={descriptionBodySourceMode}
										// error={Description === "" && hasError}
										onBlur={(event) =>
											descriptionVirtualHTML.onSyncOriginal(
												event.currentTarget.innerHTML,
												(newContent: string) => {
													onSetStateProp("description", newContent);
												}
											)
										}
									/>
								</div>
							</FormField>

							<FormField id="Banner">
								<div className="form-field-header">
									<label htmlFor="Banner" aria-label="Banner">
										Banner
									</label>
									<div className="button-group">
										<button
											type="button"
											onClick={onSetBannerBodySourceMode}
											value={"false"}
											className={`${!bannerBodySourceMode ? "selected" : ""}`}
											title={`Preview mode ${!bannerBodySourceMode ? "selected" : ""}`}
										>
											Preview
										</button>
										<button
											type="button"
											onClick={onSetBannerBodySourceMode}
											value={"true"}
											className={`${bannerBodySourceMode ? "selected" : ""}`}
											title={`Source mode ${bannerBodySourceMode ? "selected" : ""}`}
										>
											Source
										</button>
									</div>
									<div
										ref={editorBannerModeRef}
										role="alert"
										aria-live="polite"
										className="notification"
										style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}
									>
										{bannerBodySourceMode ? 'Source mode selected' : 'Preview mode selected'}
									</div>
								</div>
								<div className="form-control event-description-field">
									<RichTextInput
										id="Banner"
										required={true}
										placeholder={
											bannerBodySourceMode
												? "Write your event details banner HTML here..."
												: "Write your event banner content here..."
										}
										onChange={
											bannerBodySourceMode
												? (onBannerChangeGeneric("banner") as DivEventHandler)
												: (onBannerChangeVirtualHtml("banner") as InputTextEventHandler)
										}
										value={!bannerBodySourceMode ? bannerVirtualHTML.virtualHtml : bannerVirtualHTML.originalHtml}
										sourceMode={bannerBodySourceMode}
										// error={Description === "" && hasError}
										onBlur={(event) =>
											bannerVirtualHTML.onSyncOriginal(
												event.currentTarget.innerHTML,
												(newContent: string) => {
													onSetStateProp("banner", newContent);
												}
											)
										}
									/>
								</div>
							</FormField>

							<FormActions
								actions={[
									{
										type: "submit",
										text: isNewSpace ? "Save" : "Update",
										disabled: submitDisabled
									},
									{
										type: "button",
										variation: "secondary",
										className: "cancel",
										text: NodeFormLabels.actions.cancel,
										onClick: onCancel
									}
								]}
							/>
						</FormContainer>
					)}
				</div>
			</div>
		);

	if (
		(!isSuperAdmin && isFormDataLoaded && !isFormDataLoading) ||
		(!isSuperAdmin && isNewSpace && requested && !requesting)
	) {
		return renderUnauthorized();
	}
};
