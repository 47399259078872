import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../common/button";
import { ServiceHub } from "../../../service";

/**
 * Controls the input of the Share Content
 * Form component.
 */
interface IEventProcessFileProps {}

/**
 * The Share Content form,
 * Used to share content at any state of the application.
 *
 * @param props IShareContentFormProps
 * @returns React.FC<IShareContentFormProps>
 */
export const EventProcessFile: React.FC<IEventProcessFileProps> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const eventId = searchParams.get("eventId");
    const [fileProcessed, setFileProcessed] = React.useState(false);
    const [data, setData] = React.useState('');
    const [processingFile, setProcessingFile] = React.useState(false);
    const [filename, setFilename] = React.useState("");
    const [fileError, setFileError] = React.useState("");
    const eventsManageAPI = useRef(ServiceHub.eventManageAPI.start());
    const eventFormService = useRef(ServiceHub.eventForm.start());
    
    let formData = new FormData();

    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      setData(file)
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (fileExtension === 'xlsx' || fileExtension === 'xls' || fileExtension === 'csv') {
      setFileError('');
      setFilename(file.name);
  
    } else {
      setFileError('Invalid file format. Please upload an Excel file (.xlsx, .xls or .csv).');
    }
    };

    const submitEventData = (event) => {
      event.preventDefault();
      let formData = new FormData();
      formData.append('eventAttendanceFile', data);
      setProcessingFile(true);
      setFileProcessed(false)
      eventsManageAPI.current.processVirtualEventAttendance(parseInt(eventId), formData).then((result: any) => {
          if (result instanceof Error) {
            ServiceHub.message.error(result.message);
            throw result;
          }
          
          if (!result) {
            return;
          }

          setFileProcessed(true)
          setProcessingFile(false);
          setTimeout(() => {
            eventFormService.current.setIsProcessFilePanelOpen(false);
          }, 3000);
          // show registration successfully
        });

    }

    useEffect(() => {
      const fileUpload = document.getElementById('file-upload');
      const fileLabel = document.getElementById('file-label');
    
      const handleFocus = () => {
        fileLabel.classList.add('focused-label');
      };
    
      const handleBlur = () => {
        fileLabel.classList.remove('focused-label');
      };
    
      fileUpload.addEventListener('focus', handleFocus);
      fileUpload.addEventListener('blur', handleBlur);
    
      return () => {
        fileUpload.removeEventListener('focus', handleFocus);
        fileUpload.removeEventListener('blur', handleBlur);
      };
    }, []);

    return (
        <div className="process-virtual-event-form">
          <div className="process-virtual-event-form-input" >
              <label htmlFor="file-upload" className="custom-file-upload" id="file-label">
                Select Excel file
              </label>
              <input id="file-upload" type="file" tabIndex={0} onChange={handleFileUpload} />
          </div>
          {fileError && <p className="alert alert-error">{fileError}</p>}
          {data && !processingFile && !fileProcessed ? <p className="alert alert-info process-virtual-event-form-message" aria-label="File loaded">File {filename} Loaded.</p> : null}
          {processingFile ? <div className="custom-upload-loader"></div> : null}
          {fileProcessed ? <p className="alert alert-info process-virtual-event-form-message" aria-label="File Successfully processed">File Successfully processed.</p> : null}
          <div className="process-virtual-event-form-button">
            <Button text="Submit Event Data" onClick={submitEventData} disabled={!data || processingFile} />
          </div>
      </div>
    );
};
