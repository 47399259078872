import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { NodeAPIServiceInstance } from "../../../../service/NodeAPI";
import { ServiceHub } from "../../../../service";

/**
 * Interface for DashboardImage Box
 */
interface IDashboardImageBox {
}

/**
 * Image with link component to show on main dashboards
 * @param props
 * @returns
 */
export const DashboardImageBox: React.FC<IDashboardImageBox> = (props) => {
    const [globalBannerInfo, setGlobalBannerInfo] = useState(null)
    const [processedBannerItem, setProcessedBannerItem] = useState(null);
    const AppFileApi = useRef(ServiceHub.appFileAPI.start());

    const getGlobalBanner = async () => {
        let res = await NodeAPIServiceInstance.getCurrentGlobalBanner();
        if (res) {
            setGlobalBannerInfo(res)
        }
    };

    useEffect(() => {
        getGlobalBanner()
	}, []);

    useEffect(() => {
        const processBanner = async () => {
            if (globalBannerInfo) {
                if (globalBannerInfo.BannerImageName) {
                    try {
                        const BannerImageData = await getBannerImageData(globalBannerInfo.BannerImageName);
                        setProcessedBannerItem({ ...globalBannerInfo, BannerImageData });
                    } catch (error) {
                        setProcessedBannerItem(globalBannerInfo); // Return the original banner if there's an error
                    }
                } else {
                    setProcessedBannerItem(globalBannerInfo);
                }
            }
        };
    
        processBanner();
    }, [globalBannerInfo]);

    /**
     * Convert string urls to Blob
     * @param imagePath 
     * @returns 
     */
    const getBannerImageData = async (imagePath: string) => {
        try {
            const result = await AppFileApi.current.getBannerImageByURL(imagePath);
            const imageData = result ? URL.createObjectURL(result) : null;
            return imageData;
        } catch (error) {
            return null;
        }
    };

	return (
        processedBannerItem ?
        <div className="dashboard-image-wrapper" tabIndex={-1}>
            <div className="dashboard-image-container" tabIndex={-1}>
                <Link to={processedBannerItem.Link} tabIndex={-1}>
                    <img
                        src={processedBannerItem.BannerImageData}
                        alt={`${processedBannerItem.Title} banner`}
                        title={`${processedBannerItem.Title} banner`}
                        tabIndex={-1}
                        aria-label={`${processedBannerItem.Title} banner`}
                        role="img"
                    />
                </Link>
            </div>
            <div className="dashboard-image-bottom-banner">
                <Link to={processedBannerItem.Link}>{processedBannerItem.Title} &gt;</Link>
            </div>
        </div>
        : null
	);
};