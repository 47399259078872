import React from "react";
import { IPanelProps } from "@fluentui/react/lib/Panel";
import {
	DrawerBody,
	DrawerHeader,
	DrawerHeaderTitle,
	Drawer,
	Button,
  } from "@fluentui/react-components";
  import { Dismiss24Regular } from "@fluentui/react-icons";

/**
 * Contract for the input props of the Custom Panel component.
 */
export interface ICustomPanelProps extends IPanelProps {
	isOpen: boolean;
	onDismiss: (event) => void;
	headerText?: string;
	closeOnOutClick?: undefined | boolean;
}

/**
 * Strike App default Panel (Custom Panel).
 * Uses FluentUI components internally.
 *
 * @returns React.FC<ICustomPanelProps>
 */
export const CustomPanel: React.FC<ICustomPanelProps> = ({ isOpen, onDismiss, headerText, children, ...props }) => {
	return (
		<Drawer
			type={'overlay'}
			separator
			open={isOpen}
			position={'end'}
			size={'medium'}
			className="custom-panel-default"
			onOpenChange={onDismiss}
		>
			<DrawerHeader className="custom-panel-default-header">
				<DrawerHeaderTitle
				action={
				<Button
					appearance="subtle"
					aria-label="Close"
					icon={<Dismiss24Regular />}
					onClick={onDismiss}
					tabIndex={0}
				/>
				}> 
				<h3 aria-label={headerText}>{headerText}</h3>
				</DrawerHeaderTitle>
			</DrawerHeader>

			<DrawerBody className="custom-panel-default-body">
				{children}
			</DrawerBody>
		</Drawer>
	);
};
